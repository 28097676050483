import { inject, Injectable } from '@angular/core';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap, of, switchMap, tap } from 'rxjs';

import { ViewerTaskService } from '../../services/viewer-task.service';
import { ViewerTaskFacade } from '../../viewer-task-facade';
import * as viewerTaskActions from '../actions/viewer-tasks.actions';

@Injectable()
export class ViewerTaskEffects {
  store = inject(Store);
  actions$ = inject(Actions);
  viewerTaskService = inject(ViewerTaskService);
  viewerCoreFacade = inject(ViewerCoreFacade);
  viewerTaskFacade = inject(ViewerTaskFacade);

  fetchTasks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerTaskActions.fetchTasks),
      switchMap(({ sortBy, orderDir }) => {
        return this.viewerTaskService.fetchTasks({ sortBy, orderDir }).pipe(
          map(({ items }) => viewerTaskActions.fetchTasksSuccess({ tasks: items })),
          catchError((error) => of(viewerTaskActions.fetchTasksError({ error }))),
        );
      }),
    ),
  );

  fetchTasksSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerTaskActions.fetchTasksSuccess),
      mergeMap(({ tasks }) => {
        return of(viewerTaskActions.addTasks({ tasks }));
      }),
      tap(() => {
        this.viewerTaskService.setTaskListSeen().subscribe(() => {
          this.viewerTaskFacade.getNewTaskCount();
        });
      }),
    ),
  );

  fetchTaskById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerTaskActions.fetchTaskById),
      mergeMap(({ taskId }) => {
        return this.viewerTaskService.loadTaskById(Number(taskId)).pipe(
          map((task) => {
            return viewerTaskActions.fetchTaskByIdSuccess({ task });
          }),
          catchError((error) => of(viewerTaskActions.fetchTaskByIdError({ taskId, error }))),
        );
      }),
    ),
  );

  fetchTaskByIdSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerTaskActions.fetchTaskByIdSuccess),
      tap(({ task }) =>
        this.store.dispatch(
          viewerTaskActions.setTaskUpdating({
            taskId: task.id,
            isUpdating: false,
          }),
        ),
      ),
      map(({ task }) => {
        return viewerTaskActions.upsertTask({ task: { ...task, lastStatusUpdatedAt: new Date() } });
      }),
    ),
  );

  completeTaskById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerTaskActions.completeTaskById),
      tap(({ taskId }) =>
        this.store.dispatch(
          viewerTaskActions.setTaskUpdating({
            taskId: taskId,
            isUpdating: true,
          }),
        ),
      ),
      mergeMap(({ taskId }) => {
        return this.viewerTaskService.completeTaskById(Number(taskId)).pipe(
          map(() => {
            this.viewerCoreFacade.showToast({
              listItemType: 'iconAvatar',
              iconName: 'check_box',
              iconColor: 'blue',
              title: i18n('Task completed 🎉'),
              displayDuration: 2,
            });
            return viewerTaskActions.completeTaskByIdSuccess({ taskId });
          }),
          catchError((error) =>
            of(
              viewerTaskActions.completeTaskByIdError({ taskId, error }),
              viewerTaskActions.setTaskUpdating({
                taskId: taskId,
                isUpdating: false,
              }),
            ),
          ),
        );
      }),
    ),
  );

  completeOrUncompleteTaskBySuccessId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerTaskActions.completeTaskByIdSuccess, viewerTaskActions.uncompleteTaskByIdSuccess),
      mergeMap(({ taskId }) => {
        return of(viewerTaskActions.fetchTaskById({ taskId }));
      }),
    ),
  );

  uncompleteTaskById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerTaskActions.uncompleteTaskById),
      tap(({ taskId }) =>
        this.store.dispatch(
          viewerTaskActions.setTaskUpdating({
            taskId: taskId,
            isUpdating: true,
          }),
        ),
      ),
      mergeMap(({ taskId }) => {
        return this.viewerTaskService.uncompleteTaskById(Number(taskId)).pipe(
          map(() => {
            return viewerTaskActions.uncompleteTaskByIdSuccess({ taskId });
          }),
          catchError((error) =>
            of(
              viewerTaskActions.uncompleteTaskByIdError({ taskId, error }),
              viewerTaskActions.setTaskUpdating({
                taskId: taskId,
                isUpdating: false,
              }),
            ),
          ),
        );
      }),
    ),
  );

  onGetNewTaskCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(viewerTaskActions.getNewTaskCount),
      switchMap(() => {
        return this.viewerTaskService.getNewTaskCount().pipe(
          map((count) => viewerTaskActions.getNewTaskCountSuccess({ count })),
          catchError((error) => of(viewerTaskActions.getNewTaskCountFail({ error }))),
        );
      }),
    ),
  );
}
