import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ao-save-content-onboarding-modal',
  templateUrl: './save-content-onboarding-modal.component.html',
  styleUrl: './save-content-onboarding-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveContentOnboardingModalComponent {
  @Input() open = false;
  @Output() confirm = new EventEmitter<void>();

  onConfirm() {
    this.confirm.emit();
  }
}
