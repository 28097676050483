import { z } from 'zod';

export const ActimoLanguageTypeSchema = z.enum([
  'no change',
  'da-DK',
  'en-US',
  'es-ES',
  'et-EE',
  'nl-NL',
  'fr-FR',
  'fi-FI',
  'de-DE',
  'hu-HU',
  'it-IT',
  'ja-JP',
  'nb-NO',
  'pt-BR',
  'pl-PL',
  'ro-RO',
  'sv-SE',
  'ru-RU',
  'tr-TR',
]);

const countWords = (text: string) => text.trim().split(/\s+/).length;

const allowedWords = (min: number, max: number) =>
  z.string().refine(
    (text) => {
      const wordCount = countWords(text);
      return wordCount >= min && wordCount <= max;
    },
    {
      message: `The text should contain between ${min} and ${max} words`,
    },
  );

export const TextEnhancerSchema = z.object({
  text: allowedWords(10, 3032),
  tone: z.enum([
    'no change',
    'conversational',
    'emphatetic',
    'formal',
    'humorous',
    'informative',
    'inspirational',
    'playful',
    'reflective',
  ]),
  length: z.enum(['no change', 'extend', 'shorten']),
  language: ActimoLanguageTypeSchema,
  companyDescription: z.string().optional(),
});

export const EnhanceOptionsSchema = TextEnhancerSchema.partial().omit({ text: true });
export type EnhanceOptions = z.infer<typeof EnhanceOptionsSchema>;

export enum OpenAIModels {
  GPT_3_5_TURBO = 'gpt-35-turbo',
  GPT_3_5_TURBO_0125 = 'gpt-3.5-turbo-0125',
  GPT_4 = 'gpt-4',
  GPT_4o = 'gpt-4o',
}

export type Models = OpenAIModels; // & others models
