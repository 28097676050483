import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ZoomableImage } from '@ao/data-models';
import { MediaItem } from '@ao/shared-data-models';
import { BrowserService, WINDOW, interpolate, makeHttps } from '@ao/utilities';
import { MediaComponent } from '../media/media.component';

type MediaItemExtended = MediaItem & { zoomableImage?: ZoomableImage };

@Component({
  selector: 'ao-media-embed',
  templateUrl: './media-embed.component.html',
  styleUrls: ['./media-embed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaEmbedComponent {
  @HostBinding('class.ao-media-embed') className = true;
  @Input() set media(value: MediaItem[]) {
    this._media = value
      .filter((item) => ['image', 'video'].includes(this.type(item)))
      .map((item) => {
        // refactor image structure
        if (this.type(item) === 'image') {
          const originalImage = item.images?.find((image) => image.size?.original);
          const srcset = item.images
            ? item.images
                .filter((image) => !!image.size?.width)
                .map((image) => `${image.url} ${image.size.width}w`)
                .join(',')
            : `${item.url} ${item.width}w`;

          const width = originalImage?.size?.width || item.width;
          const height = originalImage?.size?.height || item.height;
          return {
            ...item,
            zoomableImage: {
              src: item.url,
              srcset: srcset,
              mediaHeight: this.mediaHeight(height, width) || 100,
              width: width,
              height: height,
              originalUrl: originalImage?.url || item.url,
              caption: item.caption,
              unsplash_image_id: item.unsplash_image_id,
            },
          };
        }
        return {
          ...item,
          zoomableImage: {
            mediaHeight: this.mediaHeight(item.height, item.width) || 100,
          },
        };
      });

    this._files = value.filter((item) => !['image', 'video', 'audio'].includes(this.type(item)));
    this._audio = value.filter((item) => ['audio'].includes(this.type(item)));
    this._sources = value.reduce((map, item) => {
      if (item.sources) {
        map[item.id] = this.mediaSources(item);
      }
      return map;
    }, {}) as MediaItem[];
    // Avoid loading all media initialy
    this._loaded = this._media.map((m, i) => i <= 1);

    this.paginationRatio =
      this._media.length > this.maxPaginationDots ? this._media.length / this.maxPaginationDots : 1;
  }
  maxPaginationDots = 60;
  paginationRatio = 1;

  @Input() allowInsecure?: boolean = false;
  @Input() allowFluid? = true;
  @Input() contentLanguage?: string = 'en-US';
  @Input() interpolationContext?: any = {};
  @Output() firstImageLoaded: EventEmitter<void> = new EventEmitter();
  @Output() firstImageError: EventEmitter<void> = new EventEmitter();
  @Output() mediaSwipe: EventEmitter<number> = new EventEmitter();

  get files() {
    return this._files;
  }

  get audio() {
    return this._audio;
  }

  get sources() {
    return this._sources;
  }

  get loaded() {
    return this._loaded;
  }

  // When the user is swiping we simulate a horizontal scrolling by applying
  // a transform, deltaPan tells us how much the user "swiped"
  get style() {
    let delta = this.deltaPan;
    if (this.selectedItem === 0 && this.deltaPan > 0) {
      delta = 0;
    }
    if (this.selectedItem === this._media.length - 1 && this.deltaPan < 0) {
      delta = 0;
    }
    return {
      transform: `translateX(calc(${(-this.selectedItem * 100) / this._media.length}% + ${delta}px))`,
    };
  }

  get hasNext() {
    return this.selectedItem < this._media.length - 1;
  }

  get hasPrev() {
    return this.selectedItem > 0;
  }

  get pagination() {
    const total = this._media.length;
    const totalArray = Array.from(Array(Math.round(total / this.paginationRatio)).keys());
    return totalArray;
  }
  get selectedPagination() {
    return Math.round(this.selectedItem / this.paginationRatio);
  }

  _media: MediaItemExtended[] = [];
  private _files: MediaItem[] = [];
  private _audio: MediaItem[] = [];
  private _sources: { [id: number]: any } = {};

  private _loaded: boolean[];

  // used for the swipe-enabled carousel
  private deltaPan = 0;
  private startTouch: Touch;
  panning = false;
  selectedItem = 0;
  isNativeApp: boolean;

  constructor(private browser: BrowserService, @Inject(WINDOW) private window: Window) {
    this.isNativeApp = this.browser.isNativeAppCookieSet();
  }

  @Input() inComment = false;

  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  @ViewChildren('video') videoComponents: QueryList<MediaComponent>;

  type(media: MediaItem) {
    if (!media || !media.type) {
      return '';
    }
    switch (media.type.split('/')[0]) {
      case 'image':
        return 'image';
      case 'video':
        return 'video';
      case 'audio':
        return 'audio';
      default: {
        return '';
      }
    }
  }

  status(media: MediaItem) {
    const type = media && media.type && media.type.split('/')[0];
    if (!type || type === 'image' || (media.sources && media.sources.length)) {
      return 'ready';
    } else if (media.status === 'Error') {
      return 'error';
    } else {
      return 'processing';
    }
  }

  mediaHeight(height, width) {
    const maxRatio = 1;
    return Math.min(maxRatio, height / width) * 100;
  }

  isPortrait(media: MediaItem) {
    return media.width < media.height;
  }

  mediaSources(media: MediaItem) {
    if (!media.sources || media.sources?.length === 0) {
      return [{ type: media.type, src: media.url }];
    }
    return media.sources.map(({ type, url, all }) => {
      const source = { type, src: url };
      if (all) {
        for (const res of ['1080p', '720p', '360p']) {
          if (all[res]) {
            source.src = all[res].url;
            break;
          }
        }
      }
      return source;
    });
  }

  trackByFn(index, item) {
    return item.media_file_id;
  }

  showPrev() {
    this.selectedItem = this.selectedItem - 1;
    this.mediaSwipe.emit(this._media[this.selectedItem]?.media_file_id);
    this.pauseAllVideos();
  }

  showNext() {
    this.selectedItem = this.selectedItem + 1;
    this.mediaSwipe.emit(this._media[this.selectedItem]?.media_file_id);
    if (this.selectedItem + 1 <= this._media.length - 1) {
      this._loaded[this.selectedItem + 1] = true;
    }
    this.pauseAllVideos();
  }

  onTouchStart(event: TouchEvent) {
    this.startTouch = event.touches[0];
  }

  onTouch(event: TouchEvent) {
    if (this.startTouch) {
      const deltaX = event.touches[0].clientX - this.startTouch.clientX;
      const deltaY = event.touches[0].clientY - this.startTouch.clientY;
      if (this.panning || Math.abs(deltaX) > Math.abs(deltaY)) {
        this.panning = true;
        this.pauseAllVideos();
        this.deltaPan = event.touches[0].clientX - this.startTouch.clientX;
        if (event.cancelable) {
          event.preventDefault();
        }
      } else {
        this.panning = false;
        this.startTouch = undefined;
        return;
      }
    }
  }

  pauseAllVideos() {
    if (this.videoComponents.length) {
      this.videoComponents.forEach((player) => player.stop());
    }
  }

  onTouchEnd(event) {
    const el = <HTMLElement>this.wrapper.nativeElement;
    // how much needs to be swiped in order to go to next/prev
    const swipeThreshold = el.offsetWidth / 4;
    if (this.deltaPan > swipeThreshold && this.selectedItem > 0) {
      this.showPrev();
    } else if (-this.deltaPan > swipeThreshold && this.selectedItem < this._media.length - 1) {
      this.showNext();
    }
    this.panning = false;
    this.startTouch = undefined;
    this.deltaPan = 0;
  }

  nativeAppDownloadFile(file) {
    const param = interpolate(file, this.interpolationContext, this.contentLanguage);
    // Try sending a message to iOS WebView.
    if (
      this.window['webkit'] &&
      this.window['webkit'].messageHandlers &&
      this.window['webkit'].messageHandlers.downloadFile
    ) {
      return this.window['webkit'].messageHandlers.downloadFile.postMessage({
        url: makeHttps(param, !!this.allowInsecure),
      });
    }
    // Try sending a message to Android WebView.
    else if (this.window['AndroidBridge'] && this.window['AndroidBridge'].downloadFile) {
      return this.window['AndroidBridge'].downloadFile(JSON.stringify({ url: makeHttps(param, !!this.allowInsecure) }));
    }
  }
}
