// @ts-strict
import { LOADING_STATE } from '@ao/data-models';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { Action, createReducer, on } from '@ngrx/store';
import * as viewerCoreActions from '../actions/viewer-core-store.actions';
import { routerHistoryOns } from './router-history.reducer';
import { ViewerCoreState, initialState } from './viewer-core-store.state';

const viewerCoreReducer = createReducer(
  initialState,
  on(viewerCoreActions.TriggerError, (state: ViewerCoreState, { error }) => {
    return {
      ...state,
      error: { ...state.error, ...error, statusCode: error.code },
    };
  }),

  on(viewerCoreActions.LoadClientBasicConfigSuccess, (state: ViewerCoreState, { loginConfig }) => {
    return {
      ...state,
      error: {
        ...state.error,
        contentLanguage: loginConfig.config.language,
        loginConfig: loginConfig,
      },
    };
  }),
  on(
    viewerCoreActions.SetCurrentRouteDetails,
    (state: ViewerCoreState, { origin, keycode, pageId, queryParams, extraState }) => {
      return {
        ...state,
        origin,
        keycode,
        pageId,
        previousKeycode: state.keycode,
        queryParams,
        extraState,
      };
    },
  ),
  on(viewerCoreActions.LoadAppContext, (state: ViewerCoreState) => {
    return {
      ...state,
      loadingState: LOADING_STATE.loading,
    };
  }),
  on(viewerCoreActions.LoadAppContextSuccess, (state: ViewerCoreState, { companyContext, userContext }) => {
    return {
      ...state,
      loadingState: LOADING_STATE.loaded,
      error: {},
      companyContext: {
        socialEnabled: companyContext.socialEnabled,
        chatEnabled: companyContext.chatEnabled,
        socialName: companyContext.socialName,
        saveContentEnabled: companyContext.saveContentEnabled,
        directoryEnabled: companyContext.directoryEnabled,
        defaultLanguage: companyContext.defaultLanguage,
        autoTranslationEnabled: companyContext.autoTranslationEnabled,
        termsOfUse: companyContext.termsOfUse,
        clientFeatureFlags: companyContext.clientFeatureFlags,
        supportSettings: companyContext.supportSettings,
        customFields: companyContext.customFields,
        menuExpandedDesktop: companyContext.menuExpandedDesktop,
        trackingAttributes: companyContext.trackingAttributes,
        s3UploadEnabled: companyContext.s3UploadEnabled,
        taskManagementEnabled: companyContext.taskManagementEnabled,
      },
      userContext: {
        ...state.userContext,
        contact: userContext.contact,
        unreadCounts: userContext.unreadCounts,
        contactAuthCode: userContext.contactAuthCode,
        relationships: userContext.relationships,
        employees: userContext.employees,
        country: userContext.country,
        profileSettings: userContext.profileSettings,
        pendingRecurringMsgs: userContext.pendingRecurringMsgs,
        insightsData: userContext.insightsData,
      },
    };
  }),
  on(viewerCoreActions.LoadAppContextFail, (state: ViewerCoreState, { error }) => {
    return {
      ...state,
      loadingState: LOADING_STATE.error,
      error: { ...state.error, ...error, statusCode: error.code },
    };
  }),

  on(viewerCoreActions.ResetAppContext, (state: ViewerCoreState) => {
    return { ...state, loadingState: LOADING_STATE.unloaded };
  }),

  on(viewerCoreActions.LoadUnreadCountSuccess, (state: ViewerCoreState, { counts }) => {
    return {
      ...state,
      userContext: {
        ...state.userContext,
        unreadCounts: {
          ...state.userContext.unreadCounts,
          ...counts,
        },
      },
    };
  }),

  on(viewerCoreActions.UpdateContactSuccess, (state: ViewerCoreState, { id, newValue }): ViewerCoreState => {
    return {
      ...state,
      userContext: {
        ...state.userContext,
        contact: {
          ...state.userContext.contact,
          ...(!id || id === state.userContext.contact.id ? newValue : {}),
        },
      },
    };
  }),

  on(viewerCoreActions.UpdateModalStatus, (state: ViewerCoreState, { modalStatus }) => {
    return {
      ...state,
      userContext: {
        ...state.userContext,
        contact: {
          ...state.userContext.contact,
          modal_status: {
            ...(state.userContext.contact.modal_status || {}),
            ...modalStatus,
          },
        },
      },
    };
  }),
  on(viewerCoreActions.UpdateContactStatus, (state: ViewerCoreState, { contactStatus }) => {
    return {
      ...state,
      userContext: {
        ...state.userContext,
        contact: {
          ...state.userContext.contact,
          contact_status: contactStatus,
        },
      },
    };
  }),

  on(viewerCoreActions.UpdateSystemAllowPushNotification, (state: ViewerCoreState, { system_allow_push }) => {
    return {
      ...state,
      userContext: {
        ...state.userContext,
        contact: {
          ...state.userContext.contact,
          contactDeviceData: {
            ...state.userContext.contact.contactDeviceData,
            system_allow_push,
          },
        },
      },
    };
  }),
  on(viewerCoreActions.UpdateContactInfo, (state: ViewerCoreState, { id }) => {
    return {
      ...state,
      userContext: {
        ...state.userContext,
        contact: {
          ...state.userContext.contact,
          ...(!id || id === state.userContext.contact.id ? { updateInProgress: true } : {}),
        },
      },
    };
  }),
  on(
    viewerCoreActions.UpdateContactInfoSuccess,
    (state: ViewerCoreState, { id, newValue, contactAuthCode, avatarImages }) => {
      return {
        ...state,
        userContext: {
          ...state.userContext,
          contact: {
            ...state.userContext.contact,
            ...(!id || id === state.userContext.contact.id ? newValue : {}),
            ...(avatarImages ? { avatarImages } : {}),
            updateInProgress: false,
          },
          ...(contactAuthCode ? { contactAuthCode } : {}),
        },
      };
    },
  ),
  on(viewerCoreActions.UpdateSubscriptionStatusSuccess, (state: ViewerCoreState, { isSubscribing }) => {
    return {
      ...state,
      userContext: {
        ...state.userContext,
        contact: {
          ...state.userContext.contact,
          opt_out: isSubscribing ? 0 : 1,
        },
      },
    };
  }),

  on(viewerCoreActions.SetPendingRecurringMsgs, (state: ViewerCoreState, { pendingRecurringMsgs }) => {
    return {
      ...state,
      userContext: {
        ...state.userContext,
        pendingRecurringMsgs,
      },
    };
  }),

  on(viewerCoreActions.LoadInsightsSuccess, (state: ViewerCoreState, { data }) => {
    return {
      ...state,
      userContext: {
        ...state.userContext,
        insightsData: data,
      },
    };
  }),

  on(viewerCoreActions.ShowGenericMessageToast, (state: ViewerCoreState, { toast }) => {
    return {
      ...state,
      toast,
    };
  }),
  on(viewerCoreActions.DismissGenericMessageToast, (state: ViewerCoreState) => {
    return {
      ...state,
      toast: null,
    };
  }),

  on(viewerCoreActions.UploadContactAvatarSuccess, (state: ViewerCoreState, { targetId, newValue }) => {
    if (state.userContext.contact?.id !== targetId) {
      return state;
    }
    return {
      ...state,
      userContext: {
        ...state.userContext,
        contact: {
          ...state.userContext.contact,
          avatar_url: newValue.data[0].avatarUrl,
          avatarImages: newValue.data[0].avatarImages,
        },
      },
    };
  }),

  on(viewerCoreActions.ResetCreateContact, (state: ViewerCoreState) => {
    return {
      ...state,
      userContext: {
        ...state.userContext,
        profileSettings: {
          ...state.userContext.profileSettings,
          createContactSettings: {
            ...state.userContext.profileSettings.createContactSettings,
            errors: [],
            lastCreatedContact: undefined,
          },
        },
      },
    };
  }),
  on(viewerCoreActions.CreateContactSuccess, (state: ViewerCoreState, { data }) => {
    return {
      ...state,
      userContext: {
        ...state.userContext,
        profileSettings: {
          ...state.userContext.profileSettings,
          createContactSettings: {
            ...state.userContext.profileSettings.createContactSettings,
            lastCreatedContact: data[0],
          },
        },
      },
    };
  }),
  on(viewerCoreActions.CreateContactFail, (state: ViewerCoreState, { error }) => {
    let errors = [];

    // Some errors from the backend are split up into arrays - like invalid email format
    if (error?.error?.data && error.error.data.length > 0) {
      // Avoiding duplicate errors
      // eslint-disable-next-line prefer-spread
      const errorList = [].concat.apply([], error.error.data).filter((e) => {
        return !(errors || []).includes(e);
      });
      errors = [...errorList];
    }

    if (error?.error?.code === 'DB_CREATION_FAILED' && error?.error?.error?.code === 'POLICY_DENIED') {
      errors.push({
        error: i18n('Contact information exists and updates are not allowed'),
        params: {},
      });
    } else if (error?.error?.code === 'DUPLICATE_FOUND') {
      errors.push({ code: 'DUPLICATE_FOUND' });
    } else {
      errors.push({
        error: i18n("We're sorry, something went wrong"),
        params: {},
      });
    }

    return {
      ...state,
      userContext: {
        ...state.userContext,
        profileSettings: {
          ...state.userContext.profileSettings,
          createContactSettings: {
            ...state.userContext.profileSettings.createContactSettings,
            errors: errors,
          },
        },
      },
    };
  }),
  on(viewerCoreActions.UpdateMessageTrackingAttributes, (state, { trackingAttributes }) => {
    return {
      ...state,
      companyContext: {
        ...state.companyContext,
        trackingAttributes,
      },
    };
  }),
  on(viewerCoreActions.LoadViewerSettingsSuccess, (state: ViewerCoreState, { settings }) => {
    return {
      ...state,
      viewerSettings: settings,
    };
  }),
  on(viewerCoreActions.LoadViewerSettingsFail, (state: ViewerCoreState, { error }) => {
    return {
      ...state,
      viewerSettings: { ...state.viewerSettings, error },
    };
  }),
  on(viewerCoreActions.HideNavbar, (state: ViewerCoreState) => {
    return {
      ...state,
      navbarHidden: true,
    };
  }),
  on(viewerCoreActions.ShowNavbar, (state: ViewerCoreState) => {
    return {
      ...state,
      navbarHidden: false,
    };
  }),
  on(viewerCoreActions.setStatusBarThemeSuccess, (state: ViewerCoreState, { statusBarTheme }) => {
    return {
      ...state,
      statusBarTheme,
    };
  }),
  ...routerHistoryOns,
);

export function reducer(state: ViewerCoreState | undefined, action: Action) {
  return viewerCoreReducer(state, action);
}
