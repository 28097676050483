import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonUiModule } from '@ao/common-ui';
import { UtilitiesModule, localStorageSync } from '@ao/utilities';
import { ViewerUiModule } from '@ao/viewer-ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AuthErrorComponent } from './containers/auth/error/auth-error.component';
import { ErrorComponent } from './containers/error/error.component';
import { GhostModeComponent } from './containers/ghost-mode/ghost-mode.component';
import { LoginComponent } from './containers/login/login.component';
import { RedirectComponent } from './containers/redirect/redirect.component';
import { RootComponent } from './containers/root/root.component';
import { RouterHistoryEffects } from './store/+state/effects/router-history.effects';
import { ViewerCoreEffects } from './store/+state/effects/viewer-core-store.effects';
import * as fromViewerCoreReducer from './store/+state/reducers/viewer-core-store.reducer';
import * as fromViewerCoreState from './store/+state/reducers/viewer-core-store.state';

import { ViewerToastDisplayComponent } from './containers/toast-display/toast-display.component';
import { AuthInterceptorProvider } from './utilities/auth.interceptor';
import { ViewerErrorHandler } from './utilities/viewer-error-handler';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(fromViewerCoreState.VIEWER_CORE_FEATURE_KEY, fromViewerCoreReducer.reducer, {
      metaReducers: [localStorageSync('routerHistory')],
    }),
    EffectsModule.forFeature([ViewerCoreEffects, RouterHistoryEffects]),
    CommonUiModule,
    TranslateModule,
    UtilitiesModule,
    RouterModule,
    ViewerUiModule,
  ],
  providers: [{ provide: ErrorHandler, useClass: ViewerErrorHandler }, AuthInterceptorProvider],
  declarations: [
    RootComponent,
    ErrorComponent,
    AuthErrorComponent,
    RedirectComponent,
    LoginComponent,
    GhostModeComponent,
    ViewerToastDisplayComponent,
  ],
  exports: [ViewerToastDisplayComponent],
})
export class ViewerCoreModule {}
