import { RecipientTaskListItem } from '@ao/shared-data-models';
import { dateIsFuture, dateIsPassed, dateIsToday } from '@ao/utilities';
import { marker as i18n } from '@biesbjerg/ngx-translate-extract-marker';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TaskEntity, VIEWER_TASK_STATE_KEY, ViewerTaskState } from '../viewer-tasks.state';

export const selectViewerTaskState = createFeatureSelector<ViewerTaskState>(VIEWER_TASK_STATE_KEY);

export const adapter: EntityAdapter<TaskEntity> = createEntityAdapter<TaskEntity>();

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

export const selectAllTaskEntities = createSelector(selectViewerTaskState, (state: ViewerTaskState) =>
  selectAll(state),
);

export const selectUncompletedTaskSections = createSelector(selectAllTaskEntities, (state: RecipientTaskListItem[]) => {
  const pendingTasks = state.filter((task) => task.status !== 'completed');
  const sections = [];
  const urgentTasks = pendingTasks.filter((task) => task.dueDate && dateIsPassed(task.dueDate));
  const todayTasks = pendingTasks.filter((task) => task.dueDate && dateIsToday(task.dueDate));
  const upcommingTasks = pendingTasks.filter((task) => task.dueDate && dateIsFuture(task.dueDate));
  const noDueDateTasks = pendingTasks
    .filter((task) => !task.dueDate)
    .sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt));

  if (urgentTasks.length > 0) {
    sections.push({
      id: 'urgent',
      title: i18n('Overdue'),
      tasks: urgentTasks,
      warn: true,
    });
  }
  if (todayTasks.length > 0) {
    sections.push({
      id: 'today',
      title: i18n('Today'),
      tasks: todayTasks,
    });
  }
  if (upcommingTasks.length > 0) {
    sections.push({
      id: 'upcomming',
      title: i18n('Upcoming'),
      tasks: upcommingTasks,
    });
  }
  if (noDueDateTasks.length > 0) {
    sections.push({
      id: 'noDueDate',
      title: i18n('No due date'),
      tasks: noDueDateTasks,
    });
  }
  return sections;
});

export const selectTaskIsFetching = createSelector(selectViewerTaskState, (state: ViewerTaskState) => state.isLoading);
export const selectNewTasksCount = createSelector(
  selectViewerTaskState,
  (state: ViewerTaskState) => state.newTasksCount,
);

export const selectTaskDetailsErrorById = (taskId: number) =>
  createSelector(selectViewerTaskState, (state) => {
    return state.taskDetails?.[taskId].error;
  });

export const selectTaskDetailsById = (taskId: number) =>
  createSelector(selectViewerTaskState, (state) => {
    return state.taskDetails?.[taskId].data;
  });

export const selectTaskLoadingById = (taskId: number) =>
  createSelector(selectViewerTaskState, (state) => {
    return state.taskDetails?.[taskId]?.loading;
  });
export const selectTaskUpdatingById = (taskId: number) =>
  createSelector(selectViewerTaskState, (state) => {
    return state.taskIdsUpdating?.includes(taskId) ? true : false;
  });
