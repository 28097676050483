import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as routerHistoryActions from './+state/actions/router-history.actions';
import { RouterHistoryItem } from './+state/reducers/viewer-core-store.state';
import * as routerHistorySelectors from './+state/selectors/router-history.selectors';

@Injectable({ providedIn: 'root' })
export class RouterHistoryFacade {
  history$ = this.store.pipe(select(routerHistorySelectors.selectRouterHistory));
  hasNavigated$ = this.store.pipe(select(routerHistorySelectors.selectHasNavigated));
  lastNavigatedMessage$ = this.store.pipe(select(routerHistorySelectors.selectLastNavigatedMessage));
  navigateFromSavedContent$ = this.store.pipe(select(routerHistorySelectors.selectLastNavigateFromSavedContent));
  constructor(private store: Store) {}

  toPreviousMessage(): void {
    this.store.dispatch(routerHistoryActions.toPreviousMessage());
  }

  popRoute(): void {
    this.store.dispatch(routerHistoryActions.popRoute());
  }

  clearRoute(route: RouterHistoryItem): void {
    this.store.dispatch(routerHistoryActions.clearRoute(route));
  }

  clearHistory(): void {
    this.store.dispatch(routerHistoryActions.clearHistory());
  }
}
