import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { environment } from '@ao/environments';
import { BrowserService } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Subject, filter, of } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';

export enum PushSettingsSource {
  onboarding = 'onboarding',
  notificationSettings = 'notificationSettings',
  preOptIn = 'preOptIn',
}

@Injectable({ providedIn: 'root' })
export class PushNotificationsService implements OnDestroy {
  currentMessage = new BehaviorSubject(null);
  private cometchatPushNotificationSettingsSubject = new BehaviorSubject(null);
  cometchatPushNotificationSettings$ = this.cometchatPushNotificationSettingsSubject.asObservable().pipe(
    filter((res) => !!res),
    map((res) => res['user-settings'].dnd),
  );
  private cometchatPushNotificationLoadedSubject$ = new BehaviorSubject(true);
  cometchatPushNotificationLoaded$ = this.cometchatPushNotificationLoadedSubject$.asObservable();

  private destroy$ = new Subject<void>();

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private viewerCoreFacade: ViewerCoreFacade,
    private http: HttpClient,
    private cookieService: CookieService,
    private browserService: BrowserService,
  ) {}

  // if registerAction is true it means we cannot detect changes on this browser so we need to do it here
  async requestPermission(
    contactId: number,
    clientId: number,
    registerAction: boolean,
    contactAuthCode: string,
    source: string,
  ) {
    this.registerAction(contactId, clientId, 'system', 'shown', contactAuthCode, source);

    this.angularFireMessaging.requestToken.pipe(take(1)).subscribe(
      // Permission granted
      () => {
        // for some reason this needs to be done again after requesting permissions
        this.detectTokenChanges(contactId, clientId, contactAuthCode);
        if (registerAction) {
          this.registerChanges(contactId, clientId, contactAuthCode, 'granted', source);
        }
      },
      // Permission denied
      () => {
        if (registerAction) {
          this.registerChanges(contactId, clientId, contactAuthCode, 'denied', source);
        }
      },
    );
  }

  receiveMessage() {
    // We keep it subscribed to be able to receive messages while the tab is open
    this.angularFireMessaging.messages.pipe(takeUntil(this.destroy$)).subscribe((payload) => {
      this.currentMessage.next(payload);
    });
  }

  // This is the only listener for token changes
  detectTokenChanges(contactId: number, clientId: number, contactAuthCode: string) {
    // We keep it subscribed to detect possible token changes while the tab is open
    // These are managed by firebase and possibly ocurr every 1h
    this.angularFireMessaging.tokenChanges
      .pipe(
        tap((token) => {
          if (token) {
            this.updateToken(contactId, clientId, contactAuthCode, token);
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  async toggleCometchatNotifications() {
    this.cometchatPushNotificationLoadedSubject$.next(false);
    this.cometchatPushNotificationSettings$.pipe(take(1)).subscribe(async (currentDndStatus) => {
      const { CometChat } = await import('@cometchat/chat-sdk-javascript');

      const userSettings = {
        'user-settings': {
          dnd: !currentDndStatus,
          chat: {
            allow_only_mentions: false,
            mute_group_actions: false,
            mute_all_guids: false,
            mute_all_uids: false,
          },
          call: {
            mute_all_guids: false,
            mute_all_uids: false,
          },
        },
      };

      CometChat.callExtension('push-notification', 'POST', 'v1/user-settings', userSettings).then((_) => {
        this.getCometchatPushNotificationStatus();
      });
    });
  }

  async getCometchatPushNotificationStatus() {
    const { CometChat } = await import('@cometchat/chat-sdk-javascript');

    CometChat.callExtension('push-notification', 'GET', 'v1/user-settings', null)
      .then((settings) => {
        this.cometchatPushNotificationSettingsSubject.next(settings);
        this.cometchatPushNotificationLoadedSubject$.next(true);
      })
      .finally(() => {
        this.cometchatPushNotificationLoadedSubject$.next(true);
      });
  }

  detectPermissionChanges(contactId: number, clientId: number, contactAuthCode: string) {
    if (navigator && 'permissions' in navigator && (<any>navigator).permissions.query) {
      // We get current permission state
      (<any>navigator).permissions.query({ name: 'notifications' }).then((notificationPerm) => {
        notificationPerm.onchange = () => {
          this.registerChanges(
            contactId,
            clientId,
            contactAuthCode,
            notificationPerm.state,
            PushSettingsSource.notificationSettings,
          );
        };
      });
    }
  }

  registerChanges(contactId: number, clientId: number, contactAuthCode: string, state: string, source: string) {
    if (state === 'denied') {
      this.registerAction(contactId, clientId, 'system', 'denied', contactAuthCode, source);
      this.viewerCoreFacade.updateSystemAllowPushNotification(false);
    } else if (state === 'granted') {
      this.registerAction(contactId, clientId, 'system', 'accepted', contactAuthCode, source);
      this.viewerCoreFacade.updateSystemAllowPushNotification(true);
    } else if (state === 'prompt') {
      this.registerAction(contactId, clientId, 'system', 'prompt', contactAuthCode, source);
      this.viewerCoreFacade.updateSystemAllowPushNotification(null);
    }
  }

  getCurrentNotificationPermission() {
    // For native webviews we assume that permissions are enabled until we get the bridge implementation
    if (this.browserService.isNativeAppCookieSet()) {
      return of({ state: 'granted' });
    }
    // This is also supported in native webview - but never prompted so moved below the check for native cookie
    if (navigator && 'permissions' in navigator && navigator.permissions.query) {
      // We get current permission state
      return navigator.permissions.query({ name: 'notifications' });
    }
    // Will display fallback message - that the device does not support push settings
    return of(null);
  }

  // type: 'pre' or 'system'
  registerAction(
    contactId: number,
    clientId: number,
    type: string,
    action: string,
    contactAuthCode: string,
    source?: string,
  ) {
    const contactDeviceCookie = this.cookieService.get('contact_device');
    if (!contactDeviceCookie) {
      return;
    }
    const data = {
      browserId: contactDeviceCookie || null,
      clientId: clientId,
      ...(source ? { source } : {}),
    };
    const url = `${environment.apiBaseUrl}/api/v1/device/${contactId}/register-action/${type}/${action}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'auth-code': contactAuthCode,
      }),
    };
    this.http.put(url, { data }, httpOptions).pipe(takeUntil(this.destroy$)).subscribe();
  }

  private updateToken(contactId: number, clientId: number, contactAuthCode: string, registrationToken: string) {
    const contactDeviceCookie = this.cookieService.get('contact_device');
    const registrationInfo = {
      push_key: registrationToken,
      client_id: clientId,
    };
    const url = `${environment.apiBaseUrl}/api/v1/device/${contactId}`;
    const body = contactDeviceCookie ? { update: [registrationInfo] } : { add: [registrationInfo] };
    const httpOptions = {
      headers: new HttpHeaders({
        'auth-code': contactAuthCode,
      }),
    };
    this.http.put(url, body, httpOptions).pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
