import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import * as viewerTaskActions from './+state/actions/viewer-tasks.actions';
import * as selectors from './+state/selectors/viewer-tasks.selector';
import { ViewerTaskState } from './+state/viewer-tasks.state';

@Injectable({ providedIn: 'root' })
export class ViewerTaskFacade {
  tasks$ = this.store.select(selectors.selectAllTaskEntities);
  tasksIsLoading$ = this.store.select(selectors.selectTaskIsFetching);
  uncompletedTaskSections$ = this.store.select(selectors.selectUncompletedTaskSections);
  newTasksCount$ = this.store.select(selectors.selectNewTasksCount);

  selectTaskDetailsErrorById$ = (taskId: number) =>
    this.store.select(selectors.selectTaskDetailsErrorById(taskId)).pipe(
      map((t) => {
        if (typeof t === 'object') {
          return (t as { message: string }).message || t || 'unknown error';
        }
        return undefined;
      }),
    );
  selectTaskDetailsById$ = (taskId: number) => this.store.select(selectors.selectTaskDetailsById(taskId));
  selectTaskLoadingById$ = (taskId: number) => this.store.select(selectors.selectTaskLoadingById(taskId));
  selectTaskUpdatingById$ = (taskId: number) => this.store.select(selectors.selectTaskUpdatingById(taskId));

  constructor(private store: Store<ViewerTaskState>) {}

  fetchTasks(sortBy?: string, orderDir?: string) {
    this.store.dispatch(viewerTaskActions.fetchTasks({ sortBy, orderDir }));
  }
  fetchTaskById(taskId: number) {
    this.store.dispatch(viewerTaskActions.fetchTaskById({ taskId }));
  }
  completeTask(taskId: number) {
    this.store.dispatch(viewerTaskActions.completeTaskById({ taskId }));
  }
  uncompleteTask(taskId: number) {
    this.store.dispatch(viewerTaskActions.uncompleteTaskById({ taskId }));
  }

  getNewTaskCount() {
    this.store.dispatch(viewerTaskActions.getNewTaskCount());
  }
}
