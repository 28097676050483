import { NgModule } from '@angular/core';
import { ChatStoreModule } from '@ao/chat-store';
import { ProfileStoreModule } from '@ao/profile-store';
import { SocialStoreModule } from '@ao/social-store';
import { EmitterProvider } from '@ao/utilities';
import { ViewerCoreModule } from '@ao/viewer-core';
import { ViewerTaskManagementStoreModule } from '@ao/viewer-task-management-store';
import { ViewerNotificationStoreModule } from '@ao/viewer/notification-store';
import { ViewerSavedContentModule } from '@ao/viewer/saved-content-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppEffects } from './+state/effects/app-store.effects';
import { AutoTranslationEffects } from './+state/effects/auto-translation.effects';
import { InsightsEffects } from './+state/effects/insights.effects';
import { ModuleButtongroupEffects } from './+state/effects/module-button-group.effects';
import { ModuleChartEffects } from './+state/effects/module-chart.effects';
import { ModuleConfirmEffects } from './+state/effects/module-confirm.effects';
import { ModuleContactInfoEffects } from './+state/effects/module-contact-info.effects';
import { ModuleFeedbackEffects } from './+state/effects/module-feedback.effects';
import { ModuleLikeEffects } from './+state/effects/module-like.effects';
import { ModuleMessageListEffects } from './+state/effects/module-messagelist.effect';
import { ModuleSliderEffects } from './+state/effects/module-slider.effects';
import { ModuleSmsVerifyEffects } from './+state/effects/module-sms-verify.effects';
import { ModuleUploadEffects } from './+state/effects/module-upload.effects';
import * as fromApp from './+state/reducers/app-store.reducer';
import { APP_FEATURE_KEY } from './+state/reducers/app-store.state';
import { PERSISTENT_DATA_PREFIX } from './app-store.tokens';

@NgModule({
  imports: [
    StoreModule.forFeature(APP_FEATURE_KEY, fromApp.reducer),
    EffectsModule.forFeature([
      AppEffects,
      AutoTranslationEffects,
      InsightsEffects,
      ModuleButtongroupEffects,
      ModuleConfirmEffects,
      ModuleContactInfoEffects,
      ModuleLikeEffects,
      ModuleSliderEffects,
      ModuleChartEffects,
      ModuleFeedbackEffects,
      ModuleMessageListEffects,
      ModuleUploadEffects,
      ModuleSmsVerifyEffects,
    ]),
    ViewerCoreModule,
    ProfileStoreModule,
    ChatStoreModule,
    SocialStoreModule,
    ViewerNotificationStoreModule,
    ViewerTaskManagementStoreModule,
    ViewerSavedContentModule,
  ],
  providers: [EmitterProvider, { provide: PERSISTENT_DATA_PREFIX, useValue: 'persistent-' }],
})
export class AppStoreModule {}
