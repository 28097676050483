import { Injectable } from '@angular/core';
import { ChatMessageReactions, CometChatUser, UnreadStatus } from '@ao/data-models';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as chatActions from './+state/chat-store.actions';
import * as fromChat from './+state/chat-store.reducer';
import * as chatSelectors from './+state/chat-store.selectors';
import { ChatRealtimeListenersService } from './services/chat-realtime-events.service';

@Injectable({ providedIn: 'root' })
export class ChatFacade {
  chatHasUnreadMessages$: Observable<boolean> = this.store.pipe(select(chatSelectors.selectChatHasUnreadMessages));

  constructor(private store: Store<fromChat.ChatState>, private chatRealtimeService: ChatRealtimeListenersService) {}

  updateChatHasUnread(updateStatus: UnreadStatus) {
    this.store.dispatch(chatActions.UpdateChatHasUnread({ updateStatus }));
  }

  chatOpened() {
    this.store.dispatch(chatActions.ChatOpened());
    this.initRealtimeListeners();
  }

  initRealtimeListeners(): void {
    this.chatRealtimeService.initRealtimeListeners();
  }

  groupConversationOpened(groupID: string) {
    this.store.dispatch(chatActions.fetchCurrentConversationAdmin({ groupID }));
  }
  userConversationOpened() {
    // for the moment reset currentConversationAdmin. More will be implemented in redux refactor
    this.store.dispatch(chatActions.resetCurrentConversationAdmin());
  }

  chatMessageSent(payload: {
    chatId: string;
    chatMediaUploaded: boolean;
    chatType: 'group' | 'one-to-one';
    chatMediaUploadType?: 'file' | 'video' | 'image' | 'audio';
    chatIsReply: boolean;
  }) {
    this.store.dispatch(chatActions.ChatMessageSent(payload));
  }

  chatReactionOpened(selectedMessageReactions: ChatMessageReactions) {
    this.store.dispatch(chatActions.setSelectedMessageReations({ selectedMessageReactions }));
    this.store.dispatch(chatActions.openMessageReactions());
  }

  chatReactionsClose() {
    this.store.dispatch(chatActions.closeMessageReactions());
    this.store.dispatch(chatActions.resetSelectedMessageReactions());
  }

  getSelectedMessageReactions$(): Observable<ChatMessageReactions> {
    return this.store.pipe(select(chatSelectors.selectSelectedMessageReactions));
  }
  getChatReactionsOpened$(): Observable<boolean> {
    return this.store.pipe(select(chatSelectors.selectReactionsModalOpened));
  }
  getLoggedInContact$(): Observable<CometChatUser> {
    return this.store.pipe(select(chatSelectors.selectLoggedInContact));
  }
  currentGroupAdmins() {
    return this.store.pipe(select(chatSelectors.selectCurrentGroupAdmins));
  }
  loggedInUserIsAdmin() {
    return this.store.pipe(select(chatSelectors.isLoggedinUserAnAdmin));
  }
  loggedInUser() {
    return this.store.pipe(select(chatSelectors.selectLoggedInUser));
  }
}
