import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@ao/environments';
import { RecipientTaskDetails, RecipientTaskListItem } from '@ao/shared-data-models';
import { catchError, map, throwError } from 'rxjs';

@Injectable({ providedIn: 'any' })
export class ViewerTaskService {
  http = inject(HttpClient);

  customErrorCatcher = (err?: unknown) =>
    throwError(() => {
      return (err as { error: string }).error || err;
    });

  fetchTasks({ sortBy = 'dueDate', orderDir = 'asc' }: { sortBy?: string; orderDir?: string }) {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/task`;
    return this.http
      .get<{ data: { items: RecipientTaskListItem[] } }>(url, {
        // todo fix typing here from unknown
        withCredentials: true,
        params: { orderBy: sortBy, orderDir: orderDir },
      })
      .pipe(
        map(({ data }) => {
          return data;
        }),
        catchError(this.customErrorCatcher),
      );
  }

  loadTaskById(taskId: number) {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/task/${taskId}`;
    return this.http.get<{ data: RecipientTaskDetails }>(url).pipe(
      map(({ data }) => {
        return data;
      }),
      catchError(this.customErrorCatcher),
    );
  }
  completeTaskById(taskId: number) {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/task/${taskId}/complete`;
    return this.http.put<unknown>(url, {}).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.customErrorCatcher),
    );
  }
  uncompleteTaskById(taskId: number) {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/task/${taskId}/open`;
    return this.http.put<unknown>(url, {}).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.customErrorCatcher),
    );
  }

  getNewTaskCount() {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/task/newTaskCount`;
    return this.http.get<{ data: number }>(url).pipe(
      map(({ data }) => {
        return data;
      }),
      catchError(this.customErrorCatcher),
    );
  }

  setTaskListSeen() {
    const url = `${environment.apiBaseUrl}/api/v1/viewer/task/taskListSeen`;
    return this.http.put<unknown>(url, {}).pipe(
      map((res) => {
        return res;
      }),
      catchError(this.customErrorCatcher),
    );
  }
}
