export const DEFAULT_THEME_ID = 9999999999;

export const DEFAULT_THEME = {
  id: null,
  user_id: null,
  client_id: null,
  uuid: null,
  name: 'Actimo (default)',
  screen_bg_image_url: '',
  screen_bg_images: [],
  nav_bg_color: 'ffffff',
  nav_icon_highlight_color: '4680FE',
  nav_icon_color: '24272E',
  nav_logo_url: 'https://app.actimo.com/assets/logo.png',
  nav_logo_url_x2: null,
  nav_logo_hide: 0,
  nav_logo_images: [],
  nav_click_target: null,
  nav_header_hide: 0,
  nav_bg_image_url: '',
  nav_bg_image_url_x2: null,
  nav_bg_images: [],
  footer_bg_color: 'ffffff',
  footer_txt_color: '24272E',
  footer_bg_image_url: '',
  footer_bg_image_url_x2: null,
  footer_bg_images: [],
  message_bg_image_url: '',
  message_bg_image_url_x2: null,
  message_bg_images: [],
  message_bg_stretched: 0,
  module_bg_color: 'ffffff',
  module_fg_color: '333333',
  module_txt_color: '24272E',
  module_txt_line_height: 140,
  module_link_color: '4680FE',
  module_btn_bg_color: '4680FE',
  module_btn_txt_color: 'ffffff',
  module_btn_border_color: '000000',
  module_btn_border_width: 0,
  module_btn_border_radius: 8,
  module_btn_opacity: 100,
  module_input_bg_color: 'ffffff',
  module_input_txt_color: '000000',
  module_input_border_color: '4680FE',
  font_media_id: null,
  font_bold_media_id: null,
  font_italic_media_id: null,
  font_bold_italic_media_id: null,
  match_insights_bar_bg_color: null,
  favicon_key: null,
  favicon_url: null,
  footer: '',
  created_at: '2023-09-06T12:23:11.000Z',
};
