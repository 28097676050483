import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouterFacade } from '@ao/utilities';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, withLatestFrom } from 'rxjs';
import { RouterHistoryFacade } from '../../router-history.facade';
import * as routerHistoryActions from '../actions/router-history.actions';

@Injectable({
  providedIn: 'root',
})
export class RouterHistoryEffects {
  navigate$ = createEffect(() =>
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event) => event.urlAfterRedirects),
      withLatestFrom(this.routerFacade.routeDataPage$),
      map(([urlAfterRedirects, pageType]) => routerHistoryActions.pushRoute({ path: urlAfterRedirects, pageType })),
    ),
  );

  toPreviousMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(routerHistoryActions.toPreviousMessage),
      withLatestFrom(this.routerHistoryFacade.lastNavigatedMessage$),
      withLatestFrom(this.routerHistoryFacade.navigateFromSavedContent$),
      map(([[, lastNavigatedMessage], fromSaveContent]) => {
        if (fromSaveContent !== null) {
          this.router.navigateByUrl(fromSaveContent.path);
          return routerHistoryActions.toPreviousMessageSuccess(fromSaveContent);
        } else {
          this.router.navigateByUrl(lastNavigatedMessage.path);
          return routerHistoryActions.toPreviousMessageSuccess(lastNavigatedMessage);
        }
      }),
    ),
  );

  constructor(
    private router: Router,
    private actions$: Actions,
    private routerHistoryFacade: RouterHistoryFacade,
    private routerFacade: RouterFacade,
  ) {}
}
