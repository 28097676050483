import { ClientNotificationSettings, ImageHandlerMedia, ImageSource, MediaItem } from '@ao/shared-data-models';
import { Action } from '@ngrx/store';
import { ImageMedia, PreviewImageMedia } from '../admin';
import { ClientStatus } from '../admin/core';
import { EmitterChannel, GenericListItemType } from '../common';
import { BrandingColor } from '../common/colors';
import { Contact } from '../contact/contact.model';
import { FeatureFlags } from '../features';
import { InsightsValue } from '../profile/profile.model';
import { Authentication } from './authentication.model';
import { Insights } from './insights.model';
import { MsgSettings } from './message-settings.model';
import { MessageListMessageCategory } from './messagelist.model';
import { Page, PageFull } from './page.model';
import { Sidebar } from './sidebar.model';
import { SupportSettings } from './support-settings.model';
import { Theme } from './theme.model';

export interface PendingMessage {
  id: number;
  type: string;
  crosslink_keycode: string;
  pending?: `${number}/${number}`;
  totalUnanswered: number;
  title: string;
}

export interface MessageState {
  [prop: string]: any;
}

export interface CompanyContext {
  socialEnabled: boolean;
  chatEnabled: boolean;
  saveContentEnabled: boolean;
  socialName?: string;
  directoryEnabled: boolean;
  autoTranslationEnabled: boolean;
  termsOfUse: {
    enabled: boolean;
    customText: string;
  };
  s3UploadEnabled?: boolean;
  clientFeatureFlags?: FeatureFlags;
  supportSettings?: SupportSettings;
  customFields: CustomField[];
  menuExpandedDesktop: boolean;
  defaultLanguage: string;
  trackingAttributes?: MessageTrackingAttributes;
  taskManagementEnabled?: boolean;
}

export interface UserContext {
  contact: Contact;
  unreadCounts: Record<number, number>; // index by messageId
  contactAuthCode: string;
  relationships?: Record<string, Contact>;
  employees?: number[];
  country: string;
  profileSettings: ProfileSettings;
  pendingRecurringMsgs?: PendingMessage[];
  insightsData: InsightsValue[];
}

export interface MessageTrackingAttributes {
  gaEnabled: boolean;
  clientId: number;
  clientName: string;
  messageId: number;
  socialPrivateGroups: boolean;
  isHomepage: boolean;
  clientStatus: ClientStatus;
}

export interface CustomField {
  name: string;
  dataType: string;
  field: string;
  labelTxt?: string;
  disabled?: boolean;
  required?: boolean;
}

export interface QuizData {
  mostRecent: {
    id?: number;
    modular_message_id?: number;
    categories?: any[];
    contact_id?: number;
    attempt_number?: number;
    number_of_questions?: number;
    number_of_questions_answered?: number;
    number_of_questions_correct?: number;
    points_earned?: number;
    points_possible?: number;
    updated_at?: string;
    created_at?: string;
  };
  averages: {
    count: number;
    avg_points: number;
    points_possible: number;
  };
  totals: {
    points_earned: number;
    points_possible: number;
    quizzes_attempted: number;
    quizzes_completed: number;
    quizzes_correct: number;
    quizzes_correct_percent: number;
  };
}

export interface ProfileSettings {
  showEmail?: boolean;
  showPhone?: boolean;
  showContactAccess?: boolean;
  showAllowContactInfo?: boolean;
  createContactSettings?: CreateContactSettings;
  showHomepage?: boolean;
  showManagersView?: boolean;
  organizationalLevel?: number | string;
}

export interface MessageNew {
  id: number;
  keycode: string;
  academy?: AcademyMessageSettings;
  training?: TrainingMessageSettings;
  allowUnsubscribe: boolean; // coming from BE, but not in store currently
  themeId: number;
  sidebar: Sidebar;
  sidebarId: number;
  pages: Page[];
  statpack?: any;
  subject: string;
  messageState?: MessageState;
  context?: Record<string, any>;
  pagination?: number[];
  authentication: Authentication;
  translatable: boolean;
  insights: Insights;

  // Unclear Where These belong:
  user_id: number;
  client_id: number;
  type: string;
  quiz_retake_interval: number;
  quiz_retake_max_attempts: number;
  quiz_retake_message: string;
  allow_unsubscribe: number; // boolean
  add_to_homepage: number; // boolean
  active: number; // boolean
  content_language: string;
  emitterChannel?: EmitterChannel;
  anonymous: boolean;
  isTest?: boolean;
  viewer_auth_enabled: boolean;
  likes_enabled: boolean;
  comments_enabled: boolean;
  message_sharing_enabled?: boolean;
  postId?: number; // id for post wrapper
  comments_page: number[];
  questionnaireRetake?: boolean;
  questionnaire: Questionnaire;
  disablePushNotifications?: boolean; // Set manually on a few individual messages
  auth?: {
    // seems not to be in the BE anymore...
    shouldRedirect?: boolean;
    redirectUrl?: string;
    openNewWindow?: boolean;
    shouldReloadUrl?: boolean;
    reloadOrigin?: string;
    reloadKeycode?: string;
    isError?: boolean;
    error?: any;
  };
  redirect?: {
    // maybe not coming from the BE anymore...
    url: string;
  };
  quizData: QuizData;
  client_notification_settings: ClientNotificationSettings;
  saved?: boolean;
}

export interface QuestionnaireValue {
  confirmed?: true;
  completed?: true;
  optional?: boolean;
  value: any;
  errors?: {
    [name: string]: true;
  };
}

export type Questionnaire = {
  __rehydrated?: boolean;
} & Record<string, QuestionnaireValue>;

export interface GenericMessageToast {
  listItemType: GenericListItemType;
  iconName?: string;
  iconColor?: BrandingColor;
  title: string;
  text?: string;
  link?: string;
  linkAction?: Action;
  image?: string;
  images?: ImageSource[];
  initials?: string;
  /**
   * @description The duration in seconds to display the toast
   */
  displayDuration?: number;
}

export interface Message {
  academy?: AcademyMessageSettings;
  chatEnabled: boolean;
  training?: TrainingMessageSettings;
  contact: Contact;
  theme: Theme;
  themeId?: number;
  customFields: CustomField[];
  settings: MsgSettings;
  sidebar: Sidebar;
  menuExpandedDesktop: boolean;
  defaultLanguage: string;
  insights: Insights;
  social: {
    active?: boolean;
    name?: string;
  };
  context?: { [prop: string]: any };
  pages: PageFull[];
  pagination?: number[];
  subject: string;
  contactAuthCode: string;
  authentication: Authentication;
  pendingRecurringMsgs?: PendingMessage[];
  termsOfUse: {
    enabled: boolean;
    customText: string;
  };
  s3UploadEnabled?: boolean;
  messageState?: MessageState;
  quizData: QuizData;
  country: string;
  profileSettings: ProfileSettings;
  statpack?: any;
  supportSettings?: SupportSettings;
  relationships?: { [prop: string]: Contact };
  error?: any;
  clientFeatureFlags?: { [prop: string]: number };
  nativeApp?: {
    enabled: boolean;
    smartBanner: boolean;
    appName: string;
  };
  auth?: {
    shouldRedirect?: boolean;
    redirectUrl?: string;
    openNewWindow?: boolean;
    shouldReloadUrl?: boolean;
    reloadOrigin?: string;
    reloadKeycode?: string;
    isError?: boolean;
    error?: any;
  };
  trackingAttributes?: MessageTrackingAttributes;
  disablePushNotifications?: boolean;
  redirect?: {
    url: string;
  };
  employees?: number[];
}

export interface TrainingMessageSettings {
  academyName: string;
  sectionName: string;
  nextTrainingKeycode?: string | null;
  academyKeycode: string;
  academyProgress: number;
  academyProgressUponPassed: number;
  date: string;
  retakeMaxAttempts?: number;
  attempts?: number;
  quizRetakeInterval?: number;
  academyTarget: number;
  passed: boolean;
  lastTrainingAttempt: string;
}

// todo: convert to use image while maintaining the old one? maybe fake it til you make it?
export interface AcademyMessageSettings {
  id: number;
  title: string;
  description: string;
  coverMedia?: MediaItem;
  coverImageUrl?: string;
  image?: string; // TODO: remove when coverMedia is also sent for viewer
  date: string;
  categories: MessageListMessageCategory[];
  progress: number;
  averageProgress: number;
  sections: AcademySection[] | [];
  saved?: boolean;
}

export interface AcademySection {
  id?: string;
  name: string;
  progress: number;
  disabled: boolean;
  trainings: AcademyMessageTraining[];
}

export interface AcademyMessageTraining {
  id: number;
  prefix?: string;
  keycode: string;
  title: string;
  disabled: boolean;
  date: string;
  pointsPossible?: number;
  points?: number;
  passed: boolean;
  completed: boolean;
  retakeMaxAttempts?: number;
  attempts?: number;
  media: ImageMedia | ImageHandlerMedia;
  previewImage?: PreviewImageMedia;
}

export interface CreateContactSettings {
  active: boolean;
  fields: CustomField[];
  fieldInfo: any;
  lastCreatedContact?: Contact;
  errors?: any;
  contactKeyPolicy: string[];
  authCode: string;
}

export enum Origins {
  m = 'sms',
  e = 'email',
  c = 'crosslink',
  l = 'link',
  q = 'qr',
  p = 'push',
}

export interface AoTrackEvent {
  key: string;
  event: string;
  data?: any;
}

export interface PublicLink {
  url: string;
  qrUrl: string;
  qrThumb: string;
}
