import { MessageListData, MessageListRequestParams, MsgModuleMessageList } from '@ao/data-models';
import { createAction, props } from '@ngrx/store';

export const ClearMessageListMessages = createAction(
  '[Message/Module MessageList] Clear MessageList Messages',
  props<{ module: MsgModuleMessageList; params?: MessageListRequestParams; appendMessages?: boolean }>(),
);

export const LoadMessageListData = createAction(
  '[Message/Module MessageList] Load MessageList Data',
  props<{
    module: MsgModuleMessageList;
    params?: MessageListRequestParams;
    appendMessages?: boolean;
    debounce?: number;
  }>(),
);

export const LoadMessageListDataSuccess = createAction(
  '[Message/Module MessageList] Load MessageList Data Success',
  props<{ module: MsgModuleMessageList; data: MessageListData; appendMessages?: boolean }>(),
);

export const LoadMessageListDataFail = createAction(
  '[Message/Module MessageList] Load MessageList Data Fail',
  props<{ module: MsgModuleMessageList; error: any }>(),
);

export const SearchStarted = createAction(
  '[Message/Module MessageList] Feed search started',
  props<{ searchContext: 'feed' & string }>(),
);
