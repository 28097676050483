import { selectRoutePath } from '@ao/utilities';
import { createSelector } from '@ngrx/store';
import { selectViewerCoreState } from './viewer-core-store.selectors';

export const selectRouterHistory = createSelector(selectViewerCoreState, (state) => state.routerHistory);
export const selectHasNavigated = createSelector(selectRouterHistory, (state) => state.length > 1);

export const selectHasNavigatedToMessage = createSelector(selectRouterHistory, selectRoutePath, (state, path) => {
  return state.filter((route) => route.pageType === 'MESSAGE').some((route) => route.path !== path);
});

export const selectLastNavigatedMessage = createSelector(selectRouterHistory, selectRoutePath, (state, path) => {
  const messageRoutes = state.filter((route) => route.pageType === 'MESSAGE' && route.path !== path);
  return messageRoutes[messageRoutes.length - 1];
});

export const selectLastNavigateFromSavedContent = createSelector(
  selectRouterHistory,
  selectRoutePath,
  (state, path) => {
    const messageRoutes = state.filter((route) => route.pageType === 'SAVED_CONTENT' && route.path !== path);
    return state[state.length - 2] && state[state.length - 2].pageType === 'SAVED_CONTENT'
      ? messageRoutes[messageRoutes.length - 1]
      : null;
  },
);
