import { EventEmitter } from '@angular/core';
import { ImageSource, MediaLibraryImage } from '@ao/shared-data-models';
import { InterpolateParams } from '../common';
import { MessageActionType } from './action-handler.model';
import { MsgModuleContactInfoAdmin, MsgModuleContactInfoViewer } from './message-module-contactinfo.model';
import { MessageList } from './messagelist.model';

export type MsgModuleTypes =
  | 'image'
  | 'media'
  | 'text'
  | 'like'
  | 'confirm'
  | 'contactinfo'
  | 'audio'
  | 'video'
  | 'youtube'
  | 'smsverify'
  | 'upload'
  | 'messageList'
  | 'datapicker'
  | 'messagelist'
  | 'slider'
  | 'chart'
  | 'feedback'
  | 'buttongroup'
  | 'grid'
  | 'socialFeed'
  | 'slide'
  | 'grid'
  | 'embed'
  | 'kahootchallenge';

export type MsgModule =
  | MsgModuleImage
  | MsgModuleText
  | MsgModuleLike
  | MsgModuleConfirm
  | MsgModuleContactInfoViewer
  | MsgModuleContactInfoAdmin
  | MsgModuleAudio
  | MsgModuleVideo
  | MsgModuleYoutube
  | MsgModuleSlide
  | MsgModuleUpload
  | MsgModuleMessageList
  | MsgModuleSocialFeed
  | MsgModuleDataPicker
  | MsgModuleSlider
  | MsgModuleChart
  | MsgModuleFeedback
  | MsgModuleSmsVerify
  | MsgModuleGrid
  | MsgModuleButtonGroup
  | MsgModuleKahootChallenge
  | MsgModuleEmbed;

export interface MsgModuleContainer {
  module: MsgModule;
  showRequired?: boolean;
  nextModule?: MsgModule;
  context?: InterpolateParams;
  allowInsecure?: boolean;
  currentPage?: number;
  setShowRequired?: EventEmitter<boolean>;
  moduleClick?: EventEmitter<void>;
}
export interface MsgModuleBase {
  id: number;
  type: MsgModuleTypes;
}

export interface MsgAction {
  type: MessageActionType;
  param: any;
  unsplash_image_id?: string;
  open_in_new_window?: boolean;
  show_unread?: boolean; // this is only place here for Form UI purposes, in data it is usuablly placed in the parent event though its related to the MessageActionType
}

export interface MsgActionable {
  actions: MsgAction[];
}

export interface MsgModuleFeedbackData {
  all: MsgModuleFeedbackItem[];
  previousByContact: string;
}

export type MsgModuleFeedback = MsgModuleBase &
  MsgActionable & {
    type: 'feedback';
    header: string;
    public: boolean;
    button_text: string;
    click_response: string | null;
    optional_answer: boolean;
    data?: MsgModuleFeedbackData;
    comments?: MsgModuleFeedbackItem[];
    surveyMode?: boolean;
    managerId?: string;
  };

export interface MsgModuleFeedbackItem {
  name: string;
  content: string;
  id: number;
  created_at: string;
}

export interface MsgModuleImage extends MsgModuleBase {
  type: 'image' | 'media';
  url: string;
  caption?: string;
  images: ImageSource[];
  unsplash_image_id?: string;
  media?: MediaLibraryImage[];
}

export interface MsgModuleText extends MsgModuleBase {
  type: 'text';
  text: string;
  raw_text: string;
}

export interface MsgModuleUpload extends MsgModuleBase {
  type: 'upload';
  header?: string; // might be deprecated - was used (never rendered) in composer preview?
  button_text?: string;
  options: {
    allowImages: boolean;
    allowVideo: boolean;
    allowPDFs: boolean;
    allowDocuments: boolean;
    allowMultiple: boolean;
    saveUploaded: boolean;
    allowTitle: boolean;
  };
  _files?: any[];
  upload_folder_path?: string;
  upload_folder_id?: number;
  page?: number;

  // bolow are added for viewer only i think
  _uploadedFile?: string;
  _uploadedMessage?: string;
  _uploading?: boolean;
  _processing?: boolean;
}

export interface MsgModuleLike extends MsgModuleBase {
  type: 'like';
  liked: boolean;
  likeCount: number;
  contacts: string[];
}

export interface MsgModuleConfirm extends MsgModuleBase, MsgActionable {
  type: 'confirm';
  title: string;
  confirmed: boolean;
  confirmed_at: number;
}

export interface MsgModuleContactInfo extends MsgModuleBase, MsgActionable {
  type: 'contactinfo';
  field_info: any;
  field_settings: string[];
  options_data: any[];
  group_memberships: any[];
  submitted: boolean;
  auth_code: string;
  header: string;
  fields: any[];
  data: Record<string, any>;
  anonymous: boolean;
  button_text: string;
  click_response: string;
  options_enabled: boolean;
  errors: any[];
  datapicker?: number;
  module_policy_fields?: string[];
  all_policy_fields?: { field: string; label: string; standard?: boolean }[];
  preparedFields?: boolean;
}

export interface AoMediaSource {
  type: string;
  url: string;
}

export interface MsgModuleAudio extends MsgModuleBase {
  type: 'audio';
  status: string;
  sources: AoMediaSource[];
  thumbnails: string[];
  media_file_id: string;
  cover_img_url: string;
  caption?: string;
  autoplay: boolean;
}

export interface MsgModuleVideo extends MsgModuleBase {
  type: 'video';
  status: string;
  sources: AoMediaSource[];
  thumbnails: string[];
  media_file_id: string;
  cover_img_url: string;
  cover_img_overlay_url: string;
  caption?: string;
  autoplay: boolean;
  width?: number;
  height?: number;
}

export interface MsgModuleYoutube extends MsgModuleBase {
  type: 'youtube';
  key: 'string';
}

export interface MsgModuleSmsVerify extends MsgModuleBase {
  type: 'smsverify';
  response?: ModuleSmsVerifyResponse;
}

export interface MsgModuleSlide extends MsgModuleBase {
  type: 'slide';
  image_url?: string;
  pageIndex?: number;
  pages?: number;
  sources?: any[];
}

export interface MsgModuleChart extends MsgModuleBase {
  type: 'chart';
  data_url: string;
  chart_type: string;
  options: any;
  header: string;
}

export interface DataPickerDataRow {
  [columnName: string]: string;
}

export interface DataPickerFieldMapping {
  from: string;
  to: string;
}

export interface DataPickerColumnDefinition {
  field: string;
  displayName: string;
  width: number;
}

export interface DataPickerDataSet {
  fieldMappings: DataPickerFieldMapping[];
  data: DataPickerDataRow[];
  columns: DataPickerColumnDefinition[];
  groupBy: string;
  view: string;
}

export interface MsgModuleDataPicker extends MsgModuleBase {
  type: 'datapicker';
  header: string;
  group_title: string;
  view_title: string;
  data_set: DataPickerDataSet;
  required?: boolean;
  _submitted?: boolean;
  _disabled?: boolean;
  _selected?: Record<string, any>;
}
export function isMsgModuleDataPicker(module: MsgModule): module is MsgModuleDataPicker {
  return (module as MsgModuleDataPicker).type === 'datapicker';
}

export interface MsgModuleSlider extends MsgModuleBase, MsgActionable {
  type: 'slider';
  header?: string;
  explanations: {
    [value: number]: string;
  };
  slider_type?: string;
  min_value: number;
  min_title?: string;
  max_value: number;
  max_title?: string;
  step_value: number;
  use_smiles: boolean;
  optional_answer?: boolean;
  button_text?: string;
  click_response: string;
  surveyMode?: boolean;
  lastScore?: number;
  category?: string;
}

export interface MsgModuleMessageList extends MsgModuleBase, MessageList {
  type: 'messagelist';
  subtype?: string;
  header: string;
  categories: string;
  require_categories: string;
  category_ids: number[];
  sortTerm: string;
  dateType: 'sendDate' | 'creationDate' | 'firstPublish' | 'quizRetakeDate' | 'openDate' | null;
  showNoMessages: boolean;
  numPerPage: number | string;
  includeSearchBar: boolean;
  includeRetakeQuiz: boolean;
  includeLoadMore: boolean;
  includeSocialStats: boolean;
  includeCategories: boolean;
  squaredThumbnails: boolean;
  filterUsingContact: boolean;
  unreadOnly: boolean;
  allowQuizRetake?: boolean;
  client_id: number;
  // pinned
  pinned_category_ids?: number[];
  pinned_duration_in_days?: number;
  duration_in_days?: number;
  hide_if_empty?: boolean;
}

export interface ModeMeta {
  partial_credit: boolean;
  points: number;
  explanation: string;
  correct_answer: number[];
  correct_answer_id: any; // it can be both number[] and number
  correct_count: number;
}

export interface Button extends MsgActionable {
  id: number | string;
  text: string;
  button_image_url?: string;
  button_image_url_x2?: string;
  unsplash_image_id?: string;
  button_images?: ImageSource[];
  media_file_id?: number;
  order?: number;
  on_click_response?: string;
  condition?: string;
}

export interface MsgModuleButtonGroup extends MsgModuleBase {
  type: 'buttongroup';
  buttons?: Button[];
  mode_meta?: ModeMeta;
  single_choice?: boolean;
  confirm_choice?: boolean;
  mode: string;
  header?: string;
  optional_answer?: boolean;
  surveyMode?: boolean;
}

export interface MsgModuleGrid extends MsgModuleBase {
  type: 'grid';
  tilesPerRow: number;
  items: MsgModuleGridItem[];
  isModuleCompact?: boolean; // added in BE based on previous module for viewer only
  overlayColor?: string;
  overlayOpacity?: number;
  borderColor?: string;
  borderOpacity?: number;
  backgroundImage?: string;
  backgroundImages?: ImageSource[];
  background_image_media_file_id?: string;
  unsplash_image_id?: string;
}

export interface MsgModuleGridItem extends MsgActionable {
  title?: string;
  icon?: string;
  background_image?: string;
  backgroundImages: ImageSource[];
  background_image_thumbnail?: string;
  background_image_media_file_id?: number;
  unsplash_image_id?: string;
  font_color?: string;
  overlay_color?: string;
  overlay_opacity?: number;
  show_unread?: boolean;
  unread_count?: number;
  isEmptyItem?: boolean;
}

export interface MsgModuleSocialFeed extends MsgModuleBase {
  type: 'socialFeed';
  numPerPage: number;
  groupId: number;
  hideShowMore: boolean;
  showPostButton: boolean;
  groupName: string;
  showNoMessages?: boolean;
}

export interface ModuleSmsVerifyResponse {
  date?: any;
  success: boolean;
}

export interface MsgModuleSmsVerifyData {
  phoneNumber: string;
  smsId: string;
}

export interface MsgModuleKahootChallenge extends MsgModuleBase {
  type: 'kahootchallenge';
  title: string;
  description: string;
  image_url: string;
  pin: string;
  mode: string;
}

export interface MsgModuleEmbed extends MsgModuleBase {
  type: 'embed';
  subType: string; // 'raw' || 'map'
  height: number;
  heightType: 'ratio' | 'pixels';
  sourceLink: string;
}

export function isMsgModuleUpload(module: MsgModule): module is MsgModuleUpload {
  return (module as MsgModuleUpload)?.type === 'upload';
}
