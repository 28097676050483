import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Contact, Sidebar, SidebarMenuDataItem } from '@ao/data-models';
import { BrowserService, color as c } from '@ao/utilities';

@Component({
  selector: 'ao-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  _sidebarStyle = {};
  _headerStyle = {};
  _menuItemStyle = {};

  private _sidebar;

  constructor(private browser: BrowserService) {}
  @Input() menuExpandedDesktop: boolean;
  @Input() unreadCounts: number[][];
  @Input() contact?: Contact;
  @Input() contactInitials?: string;
  @Input() profileLink?: string[];
  @Input() topBarClickTarget?: number;

  @Input() set sidebar(value: Sidebar) {
    this._sidebar = value;
    this._sidebarStyle = {
      'background-color': c(value?.menu_bg_color, '#ffffff'),
      color: c(value?.menu_color, '#000000'),
    };
    this.browser.setNativeSidebarSafeareaBgColor('--native-sidebar-safearea-bg-color', c(value?.menu_bg_color));
  }
  get sidebar() {
    return this._sidebar;
  }

  get isMobileOrTabletOS() {
    return this.browser.isMobileOrTabletOS();
  }

  @Output() menuItemClicked = new EventEmitter<SidebarMenuDataItem>();
  @Output() profileClicked = new EventEmitter<string[]>();
  @Output() topBarClicked = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();
}
