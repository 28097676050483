import { A11yModule } from '@angular/cdk/a11y';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { UtilitiesModule } from '@ao/utilities';
import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AcademyHeaderComponent } from './components/academy-components/academy-header/academy-header.component';
import { AcademyMessageComponent } from './components/academy-components/academy-message/academy-message.component';
import { AcademySectionTrainingComponent } from './components/academy-components/academy-section-training/academy-section-training.component';
import { AcademySectionComponent } from './components/academy-components/academy-section/academy-section.component';
import { ActimoInputComponent } from './components/actimo-input/actimo-input.component';
import { AdminInfoBoxComponent } from './components/admin-info-box/admin-info-box.component';
import { AnimatedNumberComponent } from './components/animated-number/animated-number.component';
import { AppConfirmationModalComponent } from './components/app-confirmation-modal/app-confirmation-modal.component';
import { AudioRecorderComponent } from './components/audio-recorder/audio-recorder.component';
import { AudioWaveformPlayerComponent } from './components/audio-waveform-player/audio-waveform-player.component';
import { AudioWaveformComponent } from './components/audio-waveform/audio-waveform.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { BackgroundImageArrayDirective } from './components/background-image-array/backround-image-array';
import {
  BaseHeaderComponent,
  BaseHeaderLeftDirective,
  BaseHeaderRightDirective,
} from './components/base-header/base-header.component';
import { ButtonComponent } from './components/button/button.component';
import { CardComponent } from './components/card/card.component';
import { CarouselPaginationComponent } from './components/carousel-pagination/carousel-pagination.component';
import { CategoryTagsComponent } from './components/category-tags/category-tags.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CirclePaginationComponent } from './components/circle-pagination/circle-pagination.component';
import { ContextMenuDirective } from './components/context-menu/context-menu-directive.directive';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { CustomIconComponent } from './components/custom-icon/custom-icon.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import { EmptyStateItemComponent } from './components/empty-state-item/empty-state-item.component';
import { ErrorStateItemComponent } from './components/error-state-item/error-state-item.component';
import { DefaultErrorComponent } from './components/error/error.component';
import { FeatureFlagDirective } from './components/feature-flag/feature-flag-directive';
import { FieldErrorComponent } from './components/field-error/field-error.component';
import { FileTypeAvatarComponent } from './components/file-type-avatar/file-type-avatar.component';
import { FlexBlockComponent } from './components/flex-block/flex-block.component';
import { FormStyleComponent } from './components/form-style/form-style.component';
import { GaugeComponent } from './components/gauge/gauge.component';
import {
  GenericListItemComponent,
  ListItemSubTitleTemplateDirective,
} from './components/generic-list-item/generic-list-item.component';
import { GoogleChartComponent } from './components/google-chart/google-chart.component';
import { GoogleChartsLoaderService } from './components/google-chart/google-charts-loader.service';
import { HighchartsComponent } from './components/highcharts/highcharts.component';
import { HrElementComponent } from './components/hr-element/hr-element.component';
import { IconAvatarComponent } from './components/icon-avatar/icon-avatar.component';
import { IconBoxComponent } from './components/icon-box/icon-box.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { IconTagComponent } from './components/icon-tag/icon-tag.component';
import { DynamicMatIconRegistry } from './components/icon/dynamic-icon-registry.service';
import { IconComponent } from './components/icon/icon.component';
import { InfiniteScrollComponent } from './components/infinite-scroll/infinite-scroll.component';
import { InfoBarComponent } from './components/info-bar/info-bar.component';
import { InlineProgressComponent } from './components/inline-progress/inline-progress.component';
import { InputDateComponent } from './components/input-date/input-date.component';
import { InputSelectComponent } from './components/input-select/input-select.component';
import { InputTagComponent } from './components/input-tag/input-tag.component';
import { InputComponent } from './components/input/input.component';
import { LabelComponent } from './components/label/label.component';
import { LinkPreviewCardComponent } from './components/link-preview-card/link-preview-card.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { LocaleSelectComponent } from './components/locale-select/locale-select.component';
import { MediaAttachmentComponent } from './components/media-attachment/media-attachment.component';
import { MediaCardComponent } from './components/media-card/media-card.component';
import { MediaEmbedComponent } from './components/media-embed/media-embed.component';
import { MediaComponent } from './components/media/media.component';
import {
  MessageModalCardComponent,
  MessageModalCardComponentFooterDirective,
} from './components/message-modal-card/message-modal-card.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { MessagelistItemSkeletonComponent } from './components/messagelist-item-skeleton/messagelist-item-skeleton.component';
import { MessagelistItemComponent } from './components/messagelist-item/messagelist-item.component';
import { ModalContainerComponent } from './components/modal/modal-container.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalContentDirective, ModalFooterDirective, ModalHeaderDirective } from './components/modal/modal.directive';
import { NotEnoughDataComponent } from './components/not-enough-data/not-enough-data.component';
import { NotificationComponent } from './components/notification/notification.component';
import { PaginationDotComponent } from './components/pagination-dots/pagination-dot.component';
import { PaginationDotsComponent } from './components/pagination-dots/pagination-dots.component';
import { CountryFlagComponent } from './components/phone-input/country-flag.component';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { ProgressLinearComponent } from './components/progress-linear/progress-linear.component';
import { ScatterChartComponent } from './components/scatter-chart/scatter-chart.component';
import { ScreenPreviewComponent } from './components/screen-preview/screen-preview.component';
import { SkeletonBlockComponent } from './components/skeleton-block/skeleton-block.component';
import { SkeletonItemComponent } from './components/skeleton-item/skeleton-item.component';
import { SocialWallSearchInputComponent } from './components/social-wall-search-input/social-wall-search-input.component';
import { SortablejsModule } from './components/sortablejs/sortablejs.module';
import { SpiderChartComponent } from './components/spider-chart/spider-chart.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { StatusCircleComponent } from './components/status-circle/status-circle.component';
import { SuspenseComponent } from './components/suspense/suspense.component';
import { TabComponent } from './components/tabs/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TagComponent } from './components/tag/tag.component';
import { TaskListItemComponent } from './components/task-list-item/task-list-item.component';
import { TaskListSectionComponent } from './components/task-list-section/task-list-section.component';
import { ThumbnailComponent } from './components/thumbnail/thumbnail.component';
import { TimeComponent } from './components/time/time.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { ToggleSliderComponent } from './components/toggle-slider/toggle-slider.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TooltipDirective } from './components/tooltip/tooltip.directive';
import { UnsplashTrackViewsComponent } from './components/unsplash-track-views/unsplash-track-views.component';
import { UnsplashTrackViewsDirective } from './components/unsplash-track-views/unsplash-track-views.directive';
import {
  UploadFileDropAreaBottomLayerTemplateDirective,
  UploadFileDropAreaComponent,
} from './components/upload-file-drop-area/upload-file-drop-area.component';
import { UploadedFileComponent } from './components/uploaded-file/uploaded-file.component';
import { ZoomableImageLightboxComponent } from './components/zoomable-image/zoomable-image-lightbox/zoomable-image-lightbox.component';
import { ZoomableImageComponent } from './components/zoomable-image/zoomable-image.component';
import { AudioRecordingService } from './services/audio-recording.service';
import { FeatureFlagService } from './services/feature-flag.service';
import { ModalService } from './services/modal/modal.service';
import { MultipartUploadService } from './services/multipart-upload.service';
import { Ng1Events } from './services/ng1-events.service';

import { SatPopoverModule } from '@ncstate/sat-popover';
import { DragHandleIconComponent } from './components/drag-handle-icon/drag-handle-icon.component';
import { FileTypeBackgroundDirective } from './components/file-type-background/file-type-background.directive';
import { PopoverItemComponent } from './components/popover/popover-item/popover-item.component';
import { PopoverComponent } from './components/popover/popover.component';
import { SliderComponent } from './components/slider/slider.component';
import { TagListComponent } from './components/tag-list/tag-list.component';
import { UnsplashTrackingService } from './services/unsplash-tracking.service';
import { ZoomableImageService } from './services/zoomable-image.service';
import { AcademyInlineProgressComponent } from './components/academy-inline-progress/academy-inline-progress.component';

const EXPORT_COMPONENTS = [
  IconComponent,
  ToggleSliderComponent,
  MediaComponent,
  CheckboxComponent,
  GoogleChartComponent,
  ProgressLinearComponent,
  InlineProgressComponent,
  ProgressBarComponent,
  GaugeComponent,
  HighchartsComponent,
  TimeComponent,
  HrElementComponent,
  AvatarComponent,
  ButtonComponent,
  IconAvatarComponent,
  IconButtonComponent,
  ThumbnailComponent,
  SpinnerComponent,
  LoadingBarComponent,
  CirclePaginationComponent,
  BaseHeaderComponent,
  BaseHeaderLeftDirective,
  BaseHeaderRightDirective,
  LocaleSelectComponent,
  ScatterChartComponent,
  SpiderChartComponent,
  AnimatedNumberComponent,
  LocaleSelectComponent,
  TabsComponent,
  TabComponent,
  DonutChartComponent,
  NotEnoughDataComponent,
  CategoryTagsComponent,
  StatusCircleComponent,
  AcademyHeaderComponent,
  AcademySectionTrainingComponent,
  AcademySectionComponent,
  AcademyMessageComponent,
  DefaultErrorComponent,
  CardComponent,
  GenericListItemComponent,
  ListItemSubTitleTemplateDirective,
  InputTagComponent,
  TagComponent,
  TagListComponent,
  LinkPreviewCardComponent,
  PhoneInputComponent,
  CountryFlagComponent,
  InputComponent,
  LabelComponent,
  SuspenseComponent,
  ZoomableImageComponent,
  ZoomableImageLightboxComponent,
  UploadedFileComponent,
  MediaAttachmentComponent,
  MediaEmbedComponent,
  UnsplashTrackViewsDirective,
  UnsplashTrackViewsComponent,
  SocialWallSearchInputComponent,
  LoaderComponent,
  CustomIconComponent,
  LabelComponent,
  TooltipComponent,
  TooltipDirective,
  ActimoInputComponent,
  AudioRecorderComponent,
  AudioWaveformComponent,
  AudioWaveformPlayerComponent,
  MessageModalComponent,
  MessageModalCardComponent,
  BackgroundImageArrayDirective,
  FeatureFlagDirective,
  MessageModalCardComponent,
  ToggleButtonComponent,
  CarouselPaginationComponent,
  PaginationDotComponent,
  PaginationDotsComponent,
  MessageModalCardComponentFooterDirective,
  NotificationComponent,
  SkeletonItemComponent,
  SkeletonBlockComponent,
  MessagelistItemSkeletonComponent,
  MessagelistItemComponent,
  AdminInfoBoxComponent,
  IconTagComponent,
  IconBoxComponent,
  ErrorStateItemComponent,
  EmptyStateItemComponent,
  ContextMenuDirective,
  ContextMenuComponent,
  InfiniteScrollComponent,
  AppConfirmationModalComponent,
  ScreenPreviewComponent,
  FormStyleComponent,
  FlexBlockComponent,
  InputSelectComponent,
  InputDateComponent,
  DrawerComponent,
  MediaCardComponent,
  TaskListItemComponent,
  TaskListSectionComponent,
  FileTypeBackgroundDirective,
  FileTypeAvatarComponent,
  TaskListItemComponent,
  TaskListSectionComponent,
  InfoBarComponent,
  FieldErrorComponent,
  ModalComponent,
  ModalContainerComponent,
  ModalHeaderDirective,
  ModalContentDirective,
  ModalFooterDirective,
  UploadFileDropAreaBottomLayerTemplateDirective,
  UploadFileDropAreaComponent,
  DragHandleIconComponent,
  PopoverComponent,
  PopoverItemComponent,
  AcademyInlineProgressComponent,
  SliderComponent,
];

@NgModule({
  imports: [
    CommonModule,
    UtilitiesModule,
    FormsModule,
    MatIconModule,
    TranslateModule,
    RouterModule,
    InfiniteScrollModule,
    FormsModule,
    ReactiveFormsModule,
    A11yModule,
    ScrollingModule,
    SortablejsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    SatPopoverModule,
  ],
  declarations: [...EXPORT_COMPONENTS],
  exports: [...EXPORT_COMPONENTS],
  providers: [
    { provide: MatIconRegistry, useClass: DynamicMatIconRegistry },
    GoogleChartsLoaderService,
    CookieService,
    ZoomableImageService,
    MultipartUploadService,
    AudioRecordingService,
    FeatureFlagService,
    UnsplashTrackingService,
    ModalService,
    Ng1Events,
  ],
})
export class InternalCommonUiModule {}

@NgModule({
  imports: [InternalCommonUiModule],
  exports: EXPORT_COMPONENTS,
})
export class CommonUiModule {}
