import { ImageHandlerMedia, ImageSource } from '@ao/shared-data-models';

export interface MessageListMessage {
  id: number;
  subject: string;
  created_at: string;
  last_sent_date: string;
  displayDate: number;
  unread: boolean;
  oneLiner: string;
  title: string;
  titlePreview?: string;
  text: string;
  textPreview?: string;
  type?: string;
  media?: ImageHandlerMedia;
  mediaPreview?: ImageHandlerMedia;
  socialStats?: MessageListMessageStats;
  categories?: MessageListMessageCategory[];
  quiz?: MessageListMessageQuizData;
  academyData?: MessageListAcademyData;
  pinned?: 1 | 0; // 1|0
  saved?: boolean;
}

export enum QuizStatus {
  PRISTINE = 'pristine',
  INCOMPLETE = 'incomplete',
  COMPLETED = 'completed',
  RETAKE_OPEN = 'retake_open',
  RETAKE_LOCKED = 'retake_locked',
}

interface MessageListMessageQuizData {
  status: QuizStatus;
  lastTaken: number;
  numberOfQuestions: number;
  numberOfQuestionsAnswered?: number;
  numberOfQuestionsCorrect?: number;
  pointsEarned?: number;
  pointsPossible?: number;
}

interface MessageListMessageStats {
  reactions?: number;
  comments?: number;
  views?: number;
}

export interface ActimoMedia {
  images?: ImageSource[];
  type: 'video' | 'youtube' | 'image';
  duration?: string;
  unsplash_image_id?: string;
}

export interface MessageListMessageCategory {
  id?: number;
  categoryId?: number;
  name: string;
  icon?: string;
}

export interface MessageListRequestParams {
  page?: number;
  searchTerm?: string;
  subtype?: string;
  filter?: string;
  categories?: string;
  includeCurrentMessage?: boolean;
  shouldTranslate?: boolean;
}

export interface MessageListData {
  options: { totalCount: number; allowQuizRetake: boolean };
  messages: MessageListMessage[];
  translatedText: Record<string, string>;
}

export interface MessageList {
  key?: string;
  filter?: string;
  range?: Array<number>;
  page?: number;
  messages?: MessageListMessage[];
  totalCount?: number;
  searchTerm?: string;
  loading?: boolean;
  loaded?: boolean;
  loadError?: { [propName: string]: any };
}

export interface MessageListAcademyData {
  trainings: number;
  trainingsPassed: number;
  progress: number;
  expirationDate?: number;
}
