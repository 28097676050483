/* eslint-disable no-useless-escape */

export const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);
export const PHONE_NUMBER_REGEX = new RegExp(/^(\+)?[0-9-() ]+$/);
export const ACTIMO_COPIED_LINK_REGEX = new RegExp(/messages\/redirect\/[a-zA-Z0-9]+\/[0-9]+\?redirect=true$/);
export const CIDR_REGEX = new RegExp(
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$/,
);
export const SMS_FROM_NAME_REGEX = new RegExp(/^[a-zA-Z0-9-_]+$/);
