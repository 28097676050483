import { Injectable, OnDestroy } from '@angular/core';
import { CometChat } from '@cometchat/chat-sdk-javascript';
import { Store } from '@ngrx/store';
import * as fromChat from '../+state/chat-store.reducer';
import * as chatActions from '../+state/chat-store.actions';
import { uuid } from '@ao/utilities';

@Injectable({
  providedIn: 'root',
})
export class ChatRealtimeListenersService implements OnDestroy {
  private NGRX_GROUP_LISTENERS: string;

  constructor(private store: Store<fromChat.ChatState>) {
    this.NGRX_GROUP_LISTENERS = uuid();
  }

  initRealtimeListeners() {
    this.initGroupListener();
  }

  initGroupListener() {
    CometChat.addGroupListener(
      this.NGRX_GROUP_LISTENERS,
      new CometChat.GroupListener({
        onGroupMemberScopeChanged: (
          message: CometChat.Action,
          changedUser: CometChat.User,
          newScope: string,
          oldScope: string,
          changedGroup: CometChat.Group,
        ) => {
          this.store.dispatch(chatActions.fetchCurrentConversationAdmin({ groupID: changedGroup.getGuid() }));
        },
      }),
    );
  }

  ngOnDestroy(): void {
    CometChat.removeUserListener(this.NGRX_GROUP_LISTENERS);
  }
}
