import { AdminSupportSettings } from '../admin/core';
import { LoginTheme } from '../message';

export interface ViewerLoginProvider {
  uuid: string;
  name: string;
  provider: string;
  authorizationUrl: string;
}

export interface ViewerLoginSettings {
  clientId: number;
  clientName: string;
  clientDomain?: string;
  appName: string;
  language: string;
  geoIpCountryCode: string;
  support: AdminSupportSettings;
  onDefaultDomain: boolean;
}

export interface ViewerLoginConfig {
  ssoEnabled: boolean;
  theme: LoginTheme;
  providers?: ViewerLoginProvider[];
  config: ViewerLoginSettings;
  contact: LoginContact;
  authKeycode?: string;
}

export enum ViewerLoginErrorCode {
  UNKNOWN = 'unknown', // when error is - tadaaa - unknown
  REJECTED = 'provider-rejected', // provider rejected the login
  EXPIRED = 'provider-expired', // login session has expired
  NOCONTACT = 'no-contact-found', // provider login succesful, but no actimo contact found
  CONFERROR = 'configuration-error', // the provider config - somehow - contain errors
}

export interface ClientListItem {
  btnBgColor: string;
  btnTxtColor: string;
  clientId: number;
  clientName: string;
  clientSsoEnabled: boolean;
  contact?: LoginContact;
  contactAuthCode: string;
  contactAuthCookieKey: string;
  contactAuthCookieValue: string;
  contactDepartment: string;
  contactId: number;
  contactLastActive: number;
  contactTitle: string;
  domain: string;
  lastRetrieved: number;
  messageFaviconUrl: string;
  messageId: number;
  messageLink: string;
}
export interface LoginContact {
  email: string;
  name: string;
  phone?: {
    countryCode: string;
    phoneNumber: string;
  };
  avatarUrl: string;
  initials: string;
}

export type ViewerLoginState = 'login' | 'ssoLogin' | 'error' | 'loading';
export type LoginAuthState =
  | 'verificationPageOne'
  | 'verificationPageTwo'
  | 'clientPicker'
  | 'emailSuccess'
  | 'error'
  | 'loggingIn'
  | 'cookiesDisabled'
  | 'authenticating';
export type AuthInProgress = 'email' | 'sms' | 'new' | 'loading';
export type DeliveryMethods = 'email' | 'sms';
