import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Self,
  SimpleChanges,
} from '@angular/core';
import { BrandingColor } from '@ao/data-models';

@Component({
  selector: 'ao-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  providers: [NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent implements OnChanges, OnInit {
  // note: this is a barebones implementation for the label/tag/badge component (the final design is not ready yet - https://www.figma.com/file/2LiYU3cUktGGnyCbm6lsBh/Labels%2C-Tags%2C-and-Badges?node-id=143%3A162 )
  @Input() iconLeft?: string;
  @Input() iconRight?: string;
  @Input() hasDismiss = false;
  @Input() color?: BrandingColor | 'default' = 'default';
  @Input() size?: 'small';
  @Input() rounded = false;
  @Input() passive = false;

  @Output() clickHandler = new EventEmitter<void>();

  constructor(@Self() private ngClass: NgClass) {}

  get rightIconSelected() {
    return this.hasDismiss ? 'close' : this.iconRight;
  }
  get iconSize() {
    return this.size === 'small' ? 12 : 16;
  }

  @HostListener('click', ['$event'])
  onClickHandler($event) {
    this.clickHandler.emit($event);
  }

  ngOnInit(): void {
    this.ngClass.ngClass = this.classNames;
    this.ngClass.ngDoCheck();
  }
  ngOnChanges(_changes: SimpleChanges): void {
    this.ngClass.ngClass = this.classNames;
    this.ngClass.ngDoCheck();
  }

  get classNames() {
    return {
      'ao-tag': true,
      'ao-tag--passive': this.passive || !this.clickHandler.observed,
      [`ao-tag--${this.size}`]: this.size,
      [`ao-tag--color-${this.color}`]: this.color,
      'ao-tag--rounded': this.rounded,
    };
  }
}
