<ng-container *ngIf="_image !== null">
  <div
    class="ao-academy-header__image"
    [minImageWidth]="600"
    [fallbackUrl]="_image.url"
    [aoBackgroundImageArray]="_image.images"
    [aoUnsplashTrackViews]="_image.unsplash_image_id"
  >
    <div class="ao-academy-header__image-overlap"></div>
  </div>
</ng-container>

<div class="ao-academy-header__padded-content">
  <div class="ao-academy-header__top-wrapper">
    <!-- Date -->
    <div class="ao-font-small ao-academy-header__date">
      {{ date | aoDateFormat: 'll' }}
    </div>
    <ao-icon
      *ngIf="saveContentEnabled"
      class="ao-academy-header__wrapper__icon"
      [name]="academyIsSaved ? 'bookmark' : 'bookmark_border'"
      [color]="academyIsSaved ? 'blue' : 'inkLighter'"
      (click)="handleSaveClick()"
    ></ao-icon>
  </div>
  <!-- categories -->
  <div *ngIf="categories && categories.length > 0">
    <ao-category-tags [categories]="categories" [useTheme]="false" [limit]="2"></ao-category-tags>
  </div>
  <div class="ao-academy-header__wrapper">
    <div class="ao-academy-header__wrapper__title">
      <!-- title -->
      <div class="ao-academy-header__title">
        {{ title }}
      </div>

      <!-- Description -->
      <div class="ao-font-small ao-academy-header__description" *ngIf="description">
        {{ description }}
      </div>
    </div>
  </div>

  <!-- progress -->
  <div class="ao-academy-header__progress">
    <ao-academy-inline-progress
      [themedContent]="false"
      [academyData]="academyData"
      [displayButton]="false"
    ></ao-academy-inline-progress>
  </div>
</div>
