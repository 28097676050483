import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { WINDOW, onceDefined, onceWithLatest } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { map } from 'rxjs';

export const savedContentGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const viewerCoreFacade = inject(ViewerCoreFacade);
  const _window: Window = inject(WINDOW);

  onceWithLatest(viewerCoreFacade.viewerSettings$, (settings) => {
    if (!settings) {
      viewerCoreFacade.loadViewerSettings();
    }
  });
  return viewerCoreFacade.viewerSettings$.pipe(
    onceDefined(),
    map((viewerSettings) => {
      if (viewerSettings?.error) {
        return router.parseUrl(`/login?redirectPath=${_window.location.pathname}`);
      }
      return viewerSettings?.featureFlags.save_content ? true : router.parseUrl(`/login`);
    }),
  );
};
