/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull viewer-frontend'
 *
 * Required dependencies: amplitude-js@^8.21.0
 * Tracking Plan Version: 19
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli
 *
 * [View Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/implementation/viewer-frontend)
 */

import amplitude, { AmplitudeClient, Callback, Config } from 'amplitude-js';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '251ee4ad84f3da26ec16373726c99e85',
  development: 'fc27851f587691c26b075e70b1e912ed'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultOptions: ConfigExt = {
  plan: {
    version: '19',
    branch: 'main',
    source: 'viewer-frontend',
    versionId: 'dfa3bd6e-1158-4f45-80d8-20ca0074e817'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '1.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { options?: Partial<ConfigExt>; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; options?: Partial<ConfigExt>; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: AmplitudeClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  clientId?: any;
  /**
   * If the user has a Manager role
   */
  isManager?: boolean;
}

export interface GroupProperties {
  "[Amplitude] Group ID"?: any;
  "[Amplitude] Group name"?: any;
  /**
   * Id of the account in Salesforce
   */
  accountId?: string;
  /**
   * Id of the client
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  clientId?: number;
  /**
   * Name of the client
   */
  clientName?: string;
  /**
   * The status of the specific platform/client
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | demo, active, inactive, churned |
   */
  clientStatus?: "demo" | "active" | "inactive" | "churned";
  /**
   * Total number of admins in the platform
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalAdmins?: number;
  /**
   * Total number of contacts in the platform
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalContacts?: number;
  /**
   * Total number of editors in the platform
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalEditors?: number;
}

export interface AcademyViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  academyId: number;
  /**
   * The original language of the content
   */
  contentLanguage: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  progress: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  sectionsCount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  trainingsCount: number;
}

export interface ChatGroupCreatedProperties {
  /**
   * ID of a chat conversation
   */
  chatId: string;
  /**
   * Amount of participants in a chat
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  chatMemberCount: number;
}

export interface ChatGroupImageEditedProperties {
  /**
   * ID of a chat conversation
   */
  chatId: string;
}

export interface ChatMessageSentProperties {
  /**
   * ID of a chat conversation
   */
  chatId: string;
  /**
   * Whether the chat message sent is a reply to another chat message or not
   */
  chatIsReply: boolean;
  /**
   * Whether chat media was uploaded as part of a chat message.
   */
  chatMediaUploaded: boolean;
  /**
   * Type of media uploaded. "file" covers PDF's and .doc's etc.
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | video, image, file, audio |
   */
  chatMediaUploadType?: "video" | "image" | "file" | "audio";
  /**
   * The type of chat conversation (e.g. group)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | one-to-one, group |
   */
  chatType: "one-to-one" | "group";
}

export interface ChatReactionGivenProperties {
  /**
   * ID of a chat conversation
   */
  chatId: string;
  /**
   * The type of chat conversation (e.g. group)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | one-to-one, group |
   */
  chatType: "one-to-one" | "group";
}

export interface InsightsPageViewedProperties {
  /**
   * The type of insights page
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | openRate, progress, knowHow, points, engagement, oneOnOne |
   */
  insightsType: "openRate" | "progress" | "knowHow" | "points" | "engagement" | "oneOnOne";
  /**
   * If the contact viewed is a report of the person viewing (manager's view)
   */
  isReport: boolean;
}

export interface MenuOpenedProperties {
  /**
   * If the menu has a notification badge
   */
  hasNotificationBadge: boolean;
  menuId: string;
}

export interface MessageViewedProperties {
  /**
   * The original language of the content
   */
  contentLanguage: string;
  /**
   * Which page the user is currently on within the Message
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  currentPage?: number;
  /**
   * If the the message is the app home page
   */
  isHomepage: boolean;
  /**
   * If the message is a training (ie. opened as part of an academy)
   */
  isTraining: boolean;
  /**
   * ID of the message
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  messageId: number;
  /**
   * The type of the message
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | normal, pulse, rating, survey, presentation |
   */
  messageType: "normal" | "pulse" | "rating" | "survey" | "presentation";
  /**
   * Where the user navigated from into a message (e.g. by clicking on a feed item, or by searching and then clicking on a feed item, or by direct link?)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | feedSearch, feed, directLink |
   */
  navSource?: "feedSearch" | "feed" | "directLink";
}

export interface NativeAppLoadedProperties {
  mobileDeviceManufacturer: string;
  mobileDeviceType: string;
  mobileOS: string;
  mobileVersion: string;
}

export interface NotificationCenterTabClickedProperties {
  /**
   * Which tab the user clicked on
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | unread, all |
   */
  activeTab: "unread" | "all";
}

export interface NotificationCenterViewedProperties {
  hasNotificationBadge: boolean;
}

export interface NotificationClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PostCreated, PostReplyCreated, ContactUpdated, PostMessageMentionCreated, CommentReplyCreated, PostWallMentionCreated, MessagePublished, CommentCreated |
   */
  notificationType:
    | "PostCreated"
    | "PostReplyCreated"
    | "ContactUpdated"
    | "PostMessageMentionCreated"
    | "CommentReplyCreated"
    | "PostWallMentionCreated"
    | "MessagePublished"
    | "CommentCreated";
}

export interface NotificationSettingsViewedProperties {
  /**
   * What (sub) page in the notification settings is being viewed
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | socialGroups, mentions, contentPublishedToMe, interestingComments, summary |
   */
  pageType: "socialGroups" | "mentions" | "contentPublishedToMe" | "interestingComments" | "summary";
}

export interface ProfileViewedProperties {
  /**
   * If the user viewing the profile is the manager
   */
  isManagersView: boolean;
}

export interface SearchStartedProperties {
  /**
   * In which context the user searched (e.g. within the feed module)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | feed |
   */
  searchContext: "feed";
}

export interface ShareButtonPressedProperties {
  /**
   * The ID of the item being liked
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  itemId: number;
  /**
   * The type of item (e.g. message, social post, comment)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | message, socialPost, comment, academy |
   */
  itemType: "message" | "socialPost" | "comment" | "academy";
  /**
   * Once the share button is pressed users can select between multiple options
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | copyLink, shareToGroup |
   */
  shareButtonPressed?: "copyLink" | "shareToGroup";
}

export interface SocialGroupViewedProperties {
  /**
   * The ID of the social group
   */
  socialGroupId: string;
  /**
   * The type of social group (company, private)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DYNAMIC, GROUP, PRIVATE |
   */
  socialGroupType: "DYNAMIC" | "GROUP" | "PRIVATE";
}

export interface SocialPostViewedProperties {
  /**
   * The ID of the social post
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  postId: number;
  /**
   * Optional (the original language of the post/comment)
   */
  postLanguage?: string;
  /**
   * The ID of the social group
   */
  socialGroupId: string;
  /**
   * The type of social group (company, private)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DYNAMIC, GROUP, PRIVATE |
   */
  socialGroupType: "DYNAMIC" | "GROUP" | "PRIVATE";
}

export interface TaskCompletedProperties {
  /**
   * This tracks how the user marks the task as completed
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | overview, topCheckbox, bottomButton |
   */
  taskCompletionContext: "overview" | "topCheckbox" | "bottomButton";
  /**
   * Id of task
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  taskId: number;
}

export interface TaskOpenedProperties {
  /**
   * Id of task
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  taskId: number;
}

export interface AcademyProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  academyId: number;
  /**
   * The original language of the content
   */
  contentLanguage: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  progress: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  sectionsCount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  trainingsCount: number;
}

export interface MessageProperties {
  /**
   * The original language of the content
   */
  contentLanguage: string;
  /**
   * Which page the user is currently on within the Message
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  currentPage?: number;
  /**
   * If the the message is the app home page
   */
  isHomepage: boolean;
  /**
   * If the message is a training (ie. opened as part of an academy)
   */
  isTraining: boolean;
  /**
   * ID of the message
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  messageId: number;
  /**
   * The type of the message
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | normal, pulse, rating, survey, presentation |
   */
  messageType: "normal" | "pulse" | "rating" | "survey" | "presentation";
}

export interface NotificationProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | PostCreated, PostReplyCreated, ContactUpdated, PostMessageMentionCreated, CommentReplyCreated, PostWallMentionCreated, MessagePublished, CommentCreated |
   */
  notificationType:
    | "PostCreated"
    | "PostReplyCreated"
    | "ContactUpdated"
    | "PostMessageMentionCreated"
    | "CommentReplyCreated"
    | "PostWallMentionCreated"
    | "MessagePublished"
    | "CommentCreated";
}

export interface SocialGroupProperties {
  /**
   * The ID of the social group
   */
  socialGroupId: string;
  /**
   * The type of social group (company, private)
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | DYNAMIC, GROUP, PRIVATE |
   */
  socialGroupType: "DYNAMIC" | "GROUP" | "PRIVATE";
}

export interface SocialPostProperties {
  /**
   * The ID of the social post
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  postId: number;
  /**
   * Optional (the original language of the post/comment)
   */
  postLanguage?: string;
}

export interface TaskProperties {
  /**
   * Id of task
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  taskId: number;
}

export class Identify implements BaseEvent {
  event_type = SpecialEventType.Identify;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class Group implements BaseEvent {
  event_type = SpecialEventType.Group;

  constructor(
    public event_properties?: GroupProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AcademyViewed implements BaseEvent {
  event_type = 'Academy Viewed';

  constructor(
    public event_properties: AcademyViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChatGroupCreated implements BaseEvent {
  event_type = 'Chat Group Created';

  constructor(
    public event_properties: ChatGroupCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChatGroupImageEdited implements BaseEvent {
  event_type = 'Chat Group Image Edited';

  constructor(
    public event_properties: ChatGroupImageEditedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChatMessageSent implements BaseEvent {
  event_type = 'Chat Message Sent';

  constructor(
    public event_properties: ChatMessageSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChatOpened implements BaseEvent {
  event_type = 'Chat Opened';
}

export class ChatReactionGiven implements BaseEvent {
  event_type = 'Chat Reaction Given';

  constructor(
    public event_properties: ChatReactionGivenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DirectoryViewed implements BaseEvent {
  event_type = 'Directory Viewed';
}

export class InsightsPageViewed implements BaseEvent {
  event_type = 'Insights Page Viewed';

  constructor(
    public event_properties: InsightsPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MenuOpened implements BaseEvent {
  event_type = 'Menu Opened';

  constructor(
    public event_properties: MenuOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MessageViewed implements BaseEvent {
  event_type = 'Message Viewed';

  constructor(
    public event_properties: MessageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NativeAppLoaded implements BaseEvent {
  event_type = 'Native App Loaded';

  constructor(
    public event_properties: NativeAppLoadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NotificationCenterTabClicked implements BaseEvent {
  event_type = 'Notification Center Tab Clicked';

  constructor(
    public event_properties: NotificationCenterTabClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NotificationCenterViewed implements BaseEvent {
  event_type = 'Notification Center Viewed';

  constructor(
    public event_properties: NotificationCenterViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NotificationClicked implements BaseEvent {
  event_type = 'Notification Clicked';

  constructor(
    public event_properties: NotificationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NotificationSettingsViewed implements BaseEvent {
  event_type = 'Notification Settings Viewed';

  constructor(
    public event_properties: NotificationSettingsViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ProfileModalViewed implements BaseEvent {
  event_type = 'Profile Modal Viewed';
}

export class ProfileViewed implements BaseEvent {
  event_type = 'Profile Viewed';

  constructor(
    public event_properties: ProfileViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SavedContentOpened implements BaseEvent {
  event_type = 'Saved Content Opened';
}

export class SearchStarted implements BaseEvent {
  event_type = 'Search Started';

  constructor(
    public event_properties: SearchStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShareButtonPressed implements BaseEvent {
  event_type = 'Share Button Pressed';

  constructor(
    public event_properties: ShareButtonPressedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SocialGroupViewed implements BaseEvent {
  event_type = 'Social Group Viewed';

  constructor(
    public event_properties: SocialGroupViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SocialMenuOpened implements BaseEvent {
  event_type = 'Social Menu Opened';
}

export class SocialPostViewed implements BaseEvent {
  event_type = 'Social Post Viewed';

  constructor(
    public event_properties: SocialPostViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TaskCompleted implements BaseEvent {
  event_type = 'Task Completed';

  constructor(
    public event_properties: TaskCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TaskOpened implements BaseEvent {
  event_type = 'Task Opened';

  constructor(
    public event_properties: TaskOpenedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TaskOverviewOpened implements BaseEvent {
  event_type = 'Task Overview Opened';
}

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: AmplitudeClient;
  private middlewares: Middleware[] = [];

  get client(): AmplitudeClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): void {
    this.disabled = options.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return;
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.getInstance();
      this.amplitude?.init(apiKey, undefined, { ...DefaultOptions, ...options.client?.options });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   * @param extra Extra unstructured data for middleware.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: IdentifyOptions,
    extra?: MiddlewareExtra
  ) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event: IdentifyEvent = {
      event_type: SpecialEventType.Identify,
      event_properties: properties,
      user_id: userId || options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({ event, extra }, payload => {
      if (payload.event.user_id) {
        this.amplitude?.setUserId(payload.event.user_id);
      }
      if (payload.event.device_id) {
        this.amplitude?.setDeviceId(payload.event.device_id);
      }

      this._identify(payload.event, options);
    });
  }

  private _identify(
    event: Event,
    options?: IdentifyOptions,
  ) {
    const amplitudeIdentify = new amplitude.Identify();
    if (event.event_properties != null) {
      for (const [key, value] of Object.entries(event.event_properties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    this.amplitude?.identify(
      amplitudeIdentify,
      options?.callback,
      // options?.errorCallback
    );
  }

  setGroup(name: string, value: string | string[], options?: GroupOptions, extra?: MiddlewareExtra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.amplitude?.setGroup(name, value);
  }

  /**
   * Identify a group and set group properties.
   *
   * @param groupType The group type.
   * @param groupName The group name.
   * @param properties The group properties.
   * @param options Optional event options.
   * @param extra Extra unstructured data for middleware.
   */
  groupIdentify(
    groupType: string,
    groupName: string | string[],
    properties?: GroupProperties,
    options?: GroupOptions,
    extra?: MiddlewareExtra,
  ) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event: GroupEvent = {
      event_type: SpecialEventType.Group,
      event_properties: properties,
      user_id: options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({ event, extra }, payload => {
      const e = payload.event;
      if (e.user_id) {
        this.amplitude?.setUserId(e.user_id);
      }
      if (e.device_id) {
        this.amplitude?.setDeviceId(e.device_id);
      }
      const amplitudeIdentify = new amplitude.Identify();
      if (e.event_properties != null) {
        for (const [key, value] of Object.entries(e.event_properties)) {
          amplitudeIdentify.set(key, value);
        }
      }
      this.amplitude?.groupIdentify(groupType, groupName, amplitudeIdentify, options?.callback);
    });
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   * @param extra Extra unstructured data for middleware.
   */
  track(event: Event, options?: EventOptions, extra?: MiddlewareExtra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const trackEvent: BaseEvent = { ...event, ...options };
    this.runMiddleware({ event: trackEvent, extra }, payload => {
      if (payload.event.user_id) {
        this.amplitude?.setUserId(payload.event.user_id);
      }
      if (payload.event.device_id) {
        this.amplitude?.setDeviceId(payload.event.device_id);
      }

      const userProperties = (payload.event as BaseEvent).user_properties;
      if (userProperties) {
        const identifyEvent: IdentifyEvent = {
          event_type: SpecialEventType.Identify,
          event_properties: userProperties,
          user_id: payload.event.user_id,
          device_id: payload.event.device_id
        };
        this._identify(identifyEvent, options);
      }

      this.amplitude?.logEvent(
        payload.event.event_type,
        payload.event.event_properties,
        options?.callback,
        // options?.errorCallback,
      );
    });
  }

  /**
   * Academy Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Academy%20Viewed)
   *
   * When the user views an academy
   *
   * Owner: Alejandro Vargas
   *
   * @param properties The event's properties (e.g. academyId)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  academyViewed(
    properties: AcademyViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new AcademyViewed(properties), options, extra);
  }

  /**
   * Chat Group Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Chat%20Group%20Created)
   *
   * Whenever a chat group is created
   *
   * Owner: Arjen van Gaal
   *
   * @param properties The event's properties (e.g. chatId)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  chatGroupCreated(
    properties: ChatGroupCreatedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ChatGroupCreated(properties), options, extra);
  }

  /**
   * Chat Group Image Edited
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Chat%20Group%20Image%20Edited)
   *
   * This event tracks when a user edits the avatar for a chat group
   *
   * Owner: Thomas Goethals
   *
   * @param properties The event's properties (e.g. chatId)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  chatGroupImageEdited(
    properties: ChatGroupImageEditedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ChatGroupImageEdited(properties), options, extra);
  }

  /**
   * Chat Message Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Chat%20Message%20Sent)
   *
   * Whenever a message within a chat is sent. (Both group and on-on-one chats)
   *
   * Owner: Arjen van Gaal
   *
   * @param properties The event's properties (e.g. chatId)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  chatMessageSent(
    properties: ChatMessageSentProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ChatMessageSent(properties), options, extra);
  }

  /**
   * Chat Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Chat%20Opened)
   *
   * This event tracks when a user opens the chat feature (conversations list) within the app or website.
   *
   * Owner: Arjen van Gaal
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  chatOpened(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ChatOpened(), options, extra);
  }

  /**
   * Chat Reaction Given
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Chat%20Reaction%20Given)
   *
   * When a emoji reaction is given to a chat message. This is different from reactions on social/messages/comments, because here the user can choose from any emoji rather than 5 predefined options.
   *
   * @param properties The event's properties (e.g. chatId)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  chatReactionGiven(
    properties: ChatReactionGivenProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ChatReactionGiven(properties), options, extra);
  }

  /**
   * Directory Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Directory%20Viewed)
   *
   * When the user views the employee directory
   *
   * Owner: Alejandro Vargas
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  directoryViewed(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new DirectoryViewed(), options, extra);
  }

  /**
   * Insights Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Insights%20Page%20Viewed)
   *
   * When a contact views an insights page
   *
   * Owner: Alejandro Vargas
   *
   * @param properties The event's properties (e.g. insightsType)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  insightsPageViewed(
    properties: InsightsPageViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new InsightsPageViewed(properties), options, extra);
  }

  /**
   * Menu Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Menu%20Opened)
   *
   * When a contact clicks and opens a menu
   *
   * Owner: Alejandro Vargas
   *
   * @param properties The event's properties (e.g. hasNotificationBadge)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  menuOpened(
    properties: MenuOpenedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new MenuOpened(properties), options, extra);
  }

  /**
   * Message Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Message%20Viewed)
   *
   * When a contact views a message
   *
   * Owner: Alejandro Vargas
   *
   * @param properties The event's properties (e.g. contentLanguage)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  messageViewed(
    properties: MessageViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new MessageViewed(properties), options, extra);
  }

  /**
   * Native App Loaded
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Native%20App%20Loaded)
   *
   * Mobile version logging for native app
   *
   * Owner: Thomas Goethals
   *
   * @param properties The event's properties (e.g. mobileDeviceManufacturer)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  nativeAppLoaded(
    properties: NativeAppLoadedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new NativeAppLoaded(properties), options, extra);
  }

  /**
   * Notification Center Tab Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Notification%20Center%20Tab%20Clicked)
   *
   * When a user clicks on a filter tab in the notification center
   *
   * Owner: Alejandro Vargas
   *
   * @param properties The event's properties (e.g. activeTab)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  notificationCenterTabClicked(
    properties: NotificationCenterTabClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new NotificationCenterTabClicked(properties), options, extra);
  }

  /**
   * Notification Center Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Notification%20Center%20Viewed)
   *
   * When the user views the Notification Center
   *
   * Owner: Alejandro Vargas
   *
   * @param properties The event's properties (e.g. hasNotificationBadge)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  notificationCenterViewed(
    properties: NotificationCenterViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new NotificationCenterViewed(properties), options, extra);
  }

  /**
   * Notification Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Notification%20Clicked)
   *
   * When a user clicks a notification in the notification center
   *
   * Owner: Alejandro Vargas
   *
   * @param properties The event's properties (e.g. notificationType)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  notificationClicked(
    properties: NotificationClickedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new NotificationClicked(properties), options, extra);
  }

  /**
   * Notification Settings Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Notification%20Settings%20Viewed)
   *
   * When the user views any of the the Notification Settings pages
   *
   * Owner: Alejandro Vargas
   *
   * @param properties The event's properties (e.g. pageType)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  notificationSettingsViewed(
    properties: NotificationSettingsViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new NotificationSettingsViewed(properties), options, extra);
  }

  /**
   * Profile Modal Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Profile%20Modal%20Viewed)
   *
   * When the user views the profile card of a colleague
   *
   * Owner: Alejandro Vargas
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  profileModalViewed(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ProfileModalViewed(), options, extra);
  }

  /**
   * Profile Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Profile%20Viewed)
   *
   * When the user views a profile (their own, or one belonging to their team members)
   *
   * Owner: Alejandro Vargas
   *
   * @param properties The event's properties (e.g. isManagersView)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  profileViewed(
    properties: ProfileViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ProfileViewed(properties), options, extra);
  }

  /**
   * Saved Content Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Saved%20Content%20Opened)
   *
   * When the Saved Content page is opened.
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  savedContentOpened(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SavedContentOpened(), options, extra);
  }

  /**
   * Search Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Search%20Started)
   *
   * When the user starts searching in the user input and we have made our first search request.
   *
   * @param properties The event's properties (e.g. searchContext)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  searchStarted(
    properties: SearchStartedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SearchStarted(properties), options, extra);
  }

  /**
   * Share Button Pressed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Share%20Button%20Pressed)
   *
   * The shared button has been pressed in the viewer
   *
   * Owner: Alejandro Vargas
   *
   * @param properties The event's properties (e.g. itemId)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  shareButtonPressed(
    properties: ShareButtonPressedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new ShareButtonPressed(properties), options, extra);
  }

  /**
   * Social Group Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Social%20Group%20Viewed)
   *
   * When a contact opens and views a social group
   *
   * Owner: Alejandro Vargas
   *
   * @param properties The event's properties (e.g. socialGroupId)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  socialGroupViewed(
    properties: SocialGroupViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SocialGroupViewed(properties), options, extra);
  }

  /**
   * Social Menu Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Social%20Menu%20Opened)
   *
   * When a contact clicks and opens the social sidebar
   *
   * Owner: Alejandro Vargas
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  socialMenuOpened(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SocialMenuOpened(), options, extra);
  }

  /**
   * Social Post Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Social%20Post%20Viewed)
   *
   * When a contact opens and views a social post
   *
   * Owner: Alejandro Vargas
   *
   * @param properties The event's properties (e.g. postId)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  socialPostViewed(
    properties: SocialPostViewedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new SocialPostViewed(properties), options, extra);
  }

  /**
   * Task Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Task%20Completed)
   *
   * This event is triggered when a user marks a task as completed
   *
   * @param properties The event's properties (e.g. taskCompletionContext)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  taskCompleted(
    properties: TaskCompletedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TaskCompleted(properties), options, extra);
  }

  /**
   * Task Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Task%20Opened)
   *
   * This event tracks when a contact opens up a task
   *
   * @param properties The event's properties (e.g. taskId)
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  taskOpened(
    properties: TaskOpenedProperties,
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TaskOpened(properties), options, extra);
  }

  /**
   * Task Overview Opened
   *
   * [View in Tracking Plan](https://data.amplitude.com/kahoot/Actimo%20-%20Viewer/events/main/latest/Task%20Overview%20Opened)
   *
   * This event tracks when the user opens the Task Overview
   *
   * @param options Amplitude event options.
   * @param extra Extra untyped parameters for use in middleware.
   */
  taskOverviewOpened(
    options?: EventOptions,
    extra?: MiddlewareExtra,
  ) {
    return this.track(new TaskOverviewOpened(), options, extra);
  }

  addEventMiddleware(middleware: Middleware): void {
    this.middlewares.push(middleware);
  }

  private runMiddleware(payload: MiddlewarePayload, next: MiddlewareNext): void {
    let curMiddlewareIndex = -1;
    const middlewareCount = this.middlewares.length;

    const middlewareNext: MiddlewareNext = curPayload => {
      curMiddlewareIndex += 1;
      if (curMiddlewareIndex < middlewareCount) {
        this.middlewares[curMiddlewareIndex](curPayload, _next);
      } else {
        next(curPayload);
      }
    };

    const _next: MiddlewareNext = middlewareCount > 0 ? middlewareNext : next;

    _next(payload);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type ConfigExt = Partial<Config> & { plan?: Plan };

export type Plan = {
  branch?: string;
  source?: string;
  version?: string;
  versionId?: string;
}

export enum SpecialEventType {
  Identify = "$identify",
  Group = "$groupidentify"
}

export type BaseEvent = {
  event_type: string;
  event_properties?: { [key: string]: any },
  plan?: Plan;
  user_id?: string;
  device_id?: string;
  user_properties?: { [key: string]: any };
}
export type IdentifyEvent = Omit<BaseEvent, 'user_properties'> & {event_type: SpecialEventType.Identify };
export type GroupEvent = Omit<BaseEvent, 'user_properties'> & { event_type: SpecialEventType.Group };
export type Event = BaseEvent | IdentifyEvent | GroupEvent;

type BaseEventOptions = Omit<BaseEvent, 'event_type' | 'event_properties'> & {
  callback?: Callback;
  errorCallback?: Callback;
};
export type EventOptions = BaseEventOptions;
export type IdentifyOptions = Omit<BaseEventOptions, 'user_properties'>;
export type GroupOptions = Omit<BaseEventOptions, 'user_properties'>;

/**
 * Unstructured object to let users pass extra data to middleware
 */
export interface MiddlewareExtra {
  [name: string]: any;
}

/**
 * Data to be processed by middleware
 */
export interface MiddlewarePayload {
  event: Event;
  extra?: MiddlewareExtra;
}

/**
 * Function called at the end of each Middleware to run the next middleware in the chain
 */
export type MiddlewareNext = (payload: MiddlewarePayload) => void;

/**
 * A function to run on the Event stream (each logEvent call)
 *
 * @param payload The event and extra data being sent
 * @param next Function to run the next middleware in the chain, not calling next will end the middleware chain
 */
export type Middleware = (payload: MiddlewarePayload, next: MiddlewareNext) => void;
