import { Inject, Injectable } from '@angular/core';

import * as Bowser from 'bowser';
import { CookieService } from 'ngx-cookie-service';
import { fromEvent, map, merge, Observable } from 'rxjs';
import { hexToRgb, isColorDark } from '../utils/colors';
import { BrowserName, BrowserOSName, BrowserPlatformType } from './browser.model';
import { WINDOW } from './tokens';

@Injectable()
export class BrowserService {
  private _cachedScrollbarWidth = undefined;

  // Expose bowser for direct usage
  public bowser: Bowser.Parser.Parser;

  connectionStatus$: Observable<'online' | 'offline'> = merge(
    fromEvent(this.window, 'online'),
    fromEvent(this.window, 'offline'),
  ).pipe(map((event) => event.type as 'online' | 'offline'));

  constructor(@Inject(WINDOW) private window: Window, private cookieService: CookieService) {
    this.initialiseBowser();
  }

  // expose for testing
  initialiseBowser(ua?: string) {
    this.bowser = Bowser.getParser(ua || this.window.navigator.userAgent);
  }

  cookiesEnabled() {
    try {
      // Create cookie
      document.cookie = 'cookietest=1; SameSite=None; Secure';
      const ret = document.cookie.includes('cookietest=');
      // Delete cookie
      document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT; SameSite=None; Secure';
      return ret;
    } catch (e) {
      return false;
    }
  }

  browserName(): BrowserName {
    return <BrowserName>this.bowser.getBrowserName();
  }

  browserOS(): BrowserOSName {
    return <BrowserOSName>this.bowser.getOSName();
  }

  platformType(): BrowserPlatformType {
    return <BrowserPlatformType>this.bowser.getPlatformType();
  }

  isMobileOS(): boolean {
    return this.platformType() === BrowserPlatformType.mobile;
  }

  isMobileOrTabletOS(): boolean {
    return this.platformType() === BrowserPlatformType.mobile || this.platformType() === BrowserPlatformType.tablet;
  }

  // Detect if the browser is running in Private Browsing mode
  isPrivateMode() {
    return new Promise((resolve) => {
      const on = () => resolve(true); // is in private mode
      const off = () => resolve(false); // not private mode
      const testLocalStorage = () => {
        try {
          if (localStorage.length) off();
          else {
            localStorage.x = 1;
            localStorage.removeItem('x');
            off();
          }
        } catch (e) {
          // Safari only enables cookie in private mode
          // if cookie is disabled then all client side storage is disabled
          // if all client side storage is disabled, then there is no point
          // in using private mode
          this.window.navigator.cookieEnabled ? on() : off();
        }
      };
      // Chrome & Opera
      if ((<any>this.window).webkitRequestFileSystem) {
        return void (<any>this.window).webkitRequestFileSystem(0, 0, off, on);
      }
      // Firefox
      if ('MozAppearance' in document.documentElement.style) {
        const db = indexedDB.open('test');
        db.onerror = on;
        db.onsuccess = off;
        return void 0;
      }
      // Safari
      // eslint-disable-next-line no-useless-escape
      const isSafari = this.window.navigator.userAgent.match(/(Version|CriOS)\/([0-9\._]+).*Safari/);
      if (isSafari) {
        const version = parseInt(isSafari[2], 10);
        if (version >= 11) {
          try {
            (<any>this.window).openDatabase(null, null, null, null);
            return off();
          } catch (_) {
            return on();
          }
        } else if (version < 11) {
          return testLocalStorage();
        }
      }

      return off();
    });
  }

  getScrollbarWidth() {
    if (typeof this._cachedScrollbarWidth === 'undefined') {
      const scrollDiv = document.createElement('div');
      scrollDiv.style.width = '100px';
      scrollDiv.style.height = '100px';
      scrollDiv.style.overflow = 'scroll';
      scrollDiv.style.position = 'absolute';
      scrollDiv.style.top = '-9999px';
      document.body.appendChild(scrollDiv);
      this._cachedScrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      document.body.removeChild(scrollDiv);
    }
    return this._cachedScrollbarWidth;
  }

  isNativeAppCookieSet() {
    return this.cookieService.check('isNativeApp');
  }

  get isAndroid() {
    return this.browserOS() === BrowserOSName.Android;
  }

  get isIOS(): boolean {
    return this.browserOS() === BrowserOSName.iOS;
  }

  get isNative(): boolean {
    return this.isAndroid || this.isIOS;
  }

  get isStandaloneIOS() {
    return (this.window.navigator as any).standalone === true;
  }

  get isStandaloneOther() {
    return window.matchMedia('(display-mode: standalone)').matches;
  }

  isPWA() {
    return this.isStandaloneIOS || this.isStandaloneOther;
  }

  supportsAddToHomescreen() {
    const os = this.browserOS();
    const browserName = this.browserName();
    const androidSupported = [BrowserName.Chrome, BrowserName.SamsungInternet];

    return this.isIOS || (this.isAndroid && androidSupported.indexOf(browserName) > -1);
  }

  isMobileSafari() {
    const isMobileSafari = this.isIOS && this.browserName() === BrowserName.Safari;
    return isMobileSafari;
  }

  // handle device status bar
  checkNativeMessageHandlerAvailable() {
    const statusBarAvaiable =
      (this.window['webkit'] &&
        this.window['webkit'].messageHandlers.setStatusBarLightContent &&
        this.window['webkit'].messageHandlers.setStatusBarDarkContent) ||
      (this.window['AndroidBridge'] &&
        this.window['AndroidBridge'].setStatusBarLightContent &&
        this.window['AndroidBridge'].setStatusBarDarkContent);

    const bodyHasClass = this.window.document.body.classList.contains('status-bar-available');
    if (statusBarAvaiable && !bodyHasClass) {
      this.window.document.body.classList.add('status-bar-available');
    }
    if (!statusBarAvaiable && bodyHasClass) {
      this.window.document.body.classList.remove('status-bar-available');
    }
    return statusBarAvaiable;
  }

  setDeviceStatusBarTheme(color: string, invertResult = false): 'dark' | 'light' {
    let backgroundIsDark = isColorDark(hexToRgb(color));
    if (invertResult) {
      backgroundIsDark = !backgroundIsDark;
    }
    if (this.isAndroidStatusBar()) {
      this.setAndroidStatusBarTheme(backgroundIsDark);
    }
    if (this.isIosStatusbar()) {
      this.setIosStatusBarTheme(backgroundIsDark);
    }
    return backgroundIsDark ? 'light' : 'dark';
  }

  setDeviceStatusBarThemeFromLatest(statusBarTheme: 'light' | 'dark') {
    if (this.isAndroidStatusBar()) {
      if (statusBarTheme === 'light') {
        this.window['AndroidBridge'].setStatusBarLightContent();
      } else {
        this.window['AndroidBridge'].setStatusBarDarkContent();
      }
    }
    if (this.isIosStatusbar()) {
      if (statusBarTheme === 'light') {
        this.window['webkit'].messageHandlers.setStatusBarLightContent.postMessage({});
      } else {
        this.window['webkit'].messageHandlers.setStatusBarDarkContent.postMessage({});
      }
    }
  }

  private isIosStatusbar() {
    return (
      this.window['webkit'] &&
      this.window['webkit'].messageHandlers.setStatusBarLightContent &&
      this.window['webkit'].messageHandlers.setStatusBarDarkContent
    );
  }
  private isAndroidStatusBar() {
    return (
      this.window['AndroidBridge'] &&
      this.window['AndroidBridge'].setStatusBarLightContent &&
      this.window['AndroidBridge'].setStatusBarDarkContent
    );
  }

  private setAndroidStatusBarTheme(backgroundIsDark: boolean) {
    if (backgroundIsDark) {
      this.window['AndroidBridge'].setStatusBarLightContent();
    } else {
      this.window['AndroidBridge'].setStatusBarDarkContent();
    }
  }
  private setIosStatusBarTheme(backgroundIsDark: boolean) {
    if (backgroundIsDark) {
      this.window['webkit'].messageHandlers.setStatusBarLightContent.postMessage({});
    } else {
      this.window['webkit'].messageHandlers.setStatusBarDarkContent.postMessage({});
    }
  }
  get checkAndroidShowAcknowledgmentsAvailable() {
    return this.window['AndroidBridge'] && this.window['AndroidBridge'].showAcknowledgments;
  }
  showAcknowledgments() {
    this.window['AndroidBridge'].showAcknowledgments();
  }

  setNativeSidebarSafeareaBgColor(property: string, value: string) {
    document.documentElement.style.setProperty(property, value);
  }
}
