import { Component, HostBinding, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthInProgress,
  ClientListItem,
  GenericMessageToast,
  LoginAuthState,
  LoginContact,
  LoginTheme,
  SupportSettings,
  ViewerLoginErrorCode,
  ViewerLoginProvider,
  ViewerLoginSettings,
  ViewerLoginState,
} from '@ao/data-models';
import { BrowserService, onceWithLatest, setDayJsLocale } from '@ao/utilities';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { CoreApiService } from '../../store/services/core-api.service';
import { ViewerCoreFacade } from '../../store/viewer-core-store.facade';

@Component({
  selector: 'viewer-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnDestroy {
  @HostBinding('class.viewer-login') className = true;
  // general settings
  theme: LoginTheme = {} as LoginTheme;
  settings: ViewerLoginSettings = {} as ViewerLoginSettings;
  redirectUrl$: Observable<string>;
  redirectPath: string;
  state$ = new BehaviorSubject<ViewerLoginState>('loading');
  _redirectUrl: string = null;
  isNativeApp = false;
  ssoEnabled = false;
  supportModalOpen = false;
  _defaultSupport: SupportSettings = {
    name: 'Actimo Support',
  };

  // actimo login specific
  remainingLoginAuthAttempts$ = new BehaviorSubject<number>(5);
  loginAuthState$ = new BehaviorSubject<LoginAuthState>('verificationPageOne');
  isPWA = false;
  authInProgress: AuthInProgress = null;
  contact: LoginContact = null;
  authKeycode: string = null;
  loginRequestId: string = null;
  clientList: ClientListItem[] = null;
  loginErrorCode: string;

  // SSO login specific
  providers: ViewerLoginProvider[] = [];
  error$: Observable<ViewerLoginErrorCode>;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private meta: Meta,
    private title: Title,
    private browser: BrowserService,
    private apiService: CoreApiService,
    private translate: TranslateService,
    private viewerCoreFacade: ViewerCoreFacade,
  ) {
    // forcing sso when testing through /login/test
    this.ssoEnabled = !!(route.snapshot.firstChild && route.snapshot.firstChild.data['forceSso']);
    this.isNativeApp = this.browser.isNativeAppCookieSet();
    this.isPWA = this.browser.isPWA();

    // For unknown reasons to me, viewport is not set in the index file, so we
    // have to set it here ourselves.
    this.meta.updateTag({
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0',
    });

    // for window title
    this.translate
      .get('Login')
      .pipe(take(1))
      .subscribe((result) => {
        this.title.setTitle(result);
      });

    // sso error codes (applied through the url since they originate from the 3rd party login page)
    this.error$ = this.activatedRoute.queryParams.pipe(
      takeUntil(this.destroy$),
      map(({ error }) => (error as ViewerLoginErrorCode) || undefined),
    );

    // set redirect redirectPath
    onceWithLatest(this.activatedRoute.queryParams, (params) => {
      this.redirectPath = params.redirectPath;
    });

    // defined if originating from a specific message
    this.redirectUrl$ = this.activatedRoute.queryParams.pipe(
      takeUntil(this.destroy$),
      map(({ redirectUrl }) => (redirectUrl as string) || undefined),
    );

    // request login settings
    onceWithLatest(this.redirectUrl$, (redirectUrl) => {
      this.apiService
        .getClientLoginConfig(redirectUrl ? decodeURIComponent(redirectUrl).split('/').slice(3, 5).join('/') : null)
        .pipe(
          tap(() => {
            this.viewerCoreFacade.setAppReady();
          }),
          withLatestFrom(this.error$),
        )
        .subscribe(
          ([{ theme, providers, config, contact, ssoEnabled, authKeycode }, error]) => {
            this.ssoEnabled = this.ssoEnabled || ssoEnabled;

            // switch between error / sso / login
            const state =
              error && error !== ViewerLoginErrorCode.EXPIRED ? 'error' : this.ssoEnabled ? 'ssoLogin' : 'login';

            this.state$.next(state);
            this.theme = theme;
            this.providers = providers;
            this.settings = config;
            this.contact = contact;
            this.authKeycode = authKeycode;
            this._redirectUrl = redirectUrl;

            if (!ssoEnabled) {
              // actimo login works only if cookies are enabled
              if (!this.browser.cookiesEnabled()) {
                this.loginAuthState$.next('cookiesDisabled');
              }
            }
            // If we can identify the client and the client has a domain, reroute to that straight away
            if (
              config.clientDomain &&
              config.clientDomain.split('/')[2].toLowerCase() !== window.location.href.split('/')[2]
            ) {
              this.route.queryParams.subscribe((params) => {
                if (params.redirectUrl) {
                  window.location.href = config.clientDomain + params.redirectUrl.split('/').slice(3).join('/');
                }
              });
            }

            // set locale based on the message youre navigating to
            const defaultLocale = 'en-US';
            setDayJsLocale(this.settings.language);
            this.translate.use(this.settings.language || defaultLocale);
          },
          // unable to fetch config - show generic error.
          () => this.state$.next('error'),
        );
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // switch between "sso login" / "actimo login" / "generic error"
  onStateChange(state) {
    this.state$.next(state);
  }

  get support() {
    return this.settings && this.settings.support ? this.settings.support : this._defaultSupport;
  }

  toggleSupportModal() {
    this.supportModalOpen = !this.supportModalOpen;
  }

  // native app & SSO - Find a different app / logout?
  onFindDifferentClick() {
    this.viewerCoreFacade.resetNative({ source: 'onFindDifferentClick' });
  }

  // ----- Actimo Login specific ------

  // Submit passcode
  onLoginAuthComplete(passcode) {
    return this.apiService
      .authenticateContact(
        this.loginRequestId,
        passcode,
        this._redirectUrl ? decodeURIComponent(this._redirectUrl).split('/').slice(3, 5).join('/') : null,
      )
      .pipe(
        map((response: any) => {
          this.clientList = response.data;
          // multiple clients - show client picker
          if (response.data.length > 1) {
            // Try to grab a contact from the response data if we don't already have one
            if (!this.contact) {
              this.contact =
                (response.data.find((r) => r.contact && (r.contact.avatarUrl || r.contact.initials)) || {}).contact ||
                null;
            }
            this.loginAuthState$.next('clientPicker');
            // single option - log in straight away
          } else {
            if (response.data[0]) {
              this.authInProgress = 'loading';
              this.onPickClient(response.data[0]);
            } else {
              // found no clients (unreactly)
              this.state$.next('error');
            }
          }
        }),
        catchError((error) => {
          // wrong passcode - reduce amount of retakes
          if (error.error.code === 'AUTH_FAILED' || error.error.code === 'AUTH_REJECT') {
            this.remainingLoginAuthAttempts$.next(error.error.attemptsRemaining || 0);
          } else {
            // show general error
            this.loginErrorCode = error.error.code;
            this.state$.next('error');
            // reset auth component
            this.loginAuthState$.next('verificationPageOne');
          }
          this.authInProgress = null;
          return of();
        }),
      )
      .subscribe();
  }

  // actimo login client picker
  onPickClient(client: ClientListItem) {
    // remove domain
    const landingFromUrl = this._redirectUrl
      ? decodeURIComponent(this._redirectUrl).split('/').slice(3).join('/')
      : false;
    // change '/x/xxxx' to 'x/xxx'
    const landingDefault = client.messageLink.slice(1);
    const landingPage = landingFromUrl || landingDefault;
    const landingPath = this.redirectPath;

    // If client has sso enabled and we're not on client's domain, we redirect it there
    if (
      client.clientSsoEnabled &&
      client.domain &&
      client.domain.split('/')[2] !== window.location.href.split('/')[2]
    ) {
      this.loginAuthState$.next('loggingIn');

      window.location.href = client.domain + landingPage;
      return;
    }

    this.apiService
      .appAuth(client.contactId, client.contactAuthCode)
      .pipe(
        map((_response: Record<string, unknown>) => {
          this.loginAuthState$.next('loggingIn');
          if (!client.clientSsoEnabled) {
            // redirect to path if defined,
            if (landingPath) {
              window.location.href = window.location.origin + landingPath;
              return;
            }
            // angular navigation is smoother since the core is already loaded - however it cannot support different domain, parantheses and # character
            if (
              client.domain &&
              client.domain.split('/')[2] === window.location.href.split('/')[2] &&
              !landingPage.includes('(') &&
              !landingPage.includes('#')
            ) {
              this.router.navigateByUrl('/' + landingPage);
            } else {
              window.location.href = '/' + landingPage;
            }
          } else {
            // this is an unreactly failsafe - should usually be taken care of by getClientLoginConfig call
            if (landingPath) {
              window.location.href = window.location.origin + landingPath;
            } else {
              window.location.href = '/' + landingPage;
            }
          }
        }),
        catchError((_error) => {
          return of();
        }),
      )
      .subscribe();
  }

  // request passcode vs return to start of the flow
  onLoginAuthResetRequest({ type, value }) {
    this.remainingLoginAuthAttempts$.next(5);
    switch (type) {
      case 'sms':
      case 'email': {
        this.authInProgress = type;

        const payload = type === 'sms' ? { phone_number: value } : { email: value };

        this.apiService
          .requestLogin(payload)
          .pipe(
            map((response: { data?: { requestId: string }[] }) => {
              const reqId = response?.data?.[0]?.requestId;
              if (reqId) {
                this.loginAuthState$.next('verificationPageTwo');
                this.loginRequestId = reqId;
                this.authInProgress = null;
              } else {
                throw response;
              }
            }),
            catchError((errorResponse) => {
              this.loginErrorCode = errorResponse?.message;
              this.state$.next('error');
              this.loginRequestId = null;
              this.authInProgress = null;
              return of();
            }),
          )
          .subscribe();
        break;
      }
      case 'new':
        this.loginAuthState$.next('verificationPageOne');
        this.loginRequestId = null;
        this.authInProgress = null;
        break;
    }
  }

  onShowErrorToast(toast: GenericMessageToast) {
    this.viewerCoreFacade.showToast(toast);
  }

  logout() {
    onceWithLatest(this.viewerCoreFacade.keycode$, this.viewerCoreFacade.origin$, (keycode, origin) => {
      this.viewerCoreFacade.logout(keycode, origin);
    });
  }
}
