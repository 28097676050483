import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export enum EmptyStateItemType {
  ContactDirectory,
  SubordinateDirectory,
}

@Component({
  selector: 'ao-empty-state-item',
  templateUrl: './empty-state-item.component.html',
  styleUrls: ['./empty-state-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateItemComponent {
  @HostBinding('class.ao-empty-state-item') readonly className = true;

  @Input() type: EmptyStateItemType;

  readonly EmptyStateItemType = EmptyStateItemType;
}
