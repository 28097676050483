import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MediaPlayerType } from '@ao/data-models';
import { fixProtocol } from '@ao/utilities';
import { videojsYoutube } from '../../../vendor/videojs-youtube';

let youtubeLoaded = false;
let videojs;

@Component({
  selector: 'ao-media',
  template: '',
  styleUrls: ['./media.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaComponent implements AfterViewInit, OnDestroy, OnChanges {
  // third party (video-js) library definition
  className = 'video-js vjs-default-skin';
  private player: any;

  // inputs
  @Input() type: MediaPlayerType = 'video';
  @Input() sources: { type: string; src: string }[] = [];
  @Input() autoplay = false;
  @Input() poster: string;
  @Input() fluid = false;
  @Input() parentHeight = false;

  // outputs
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() timeupdate = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() ended = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() pause = new EventEmitter();
  @Output() mediaLoaded = new EventEmitter();
  @Output() mediaLoadError = new EventEmitter();

  // element reference
  @ViewChild('target') target: ElementRef;

  get duration(): number {
    return this.player && this.player.duration();
  }

  get currentTime(): number {
    return this.player && this.player.currentTime();
  }

  set currentTime(value: number) {
    if (this.player) this.player.currentTime(value);
  }

  public play() {
    if (this.player) this.player.play();
  }

  public stop() {
    if (this.player) this.player.pause();
  }

  private get playerJsConfig(): any {
    return {
      sources: this.sources.map(({ type, src }) => ({ type, src: fixProtocol(src) })),
      fluid: this.fluid,
      ...(this.type === 'youtube'
        ? {
            techOrder: ['youtube'],
            controls: false,
            youtube: { ytControls: 2, enablePrivacyEnhancedMode: true },
            children: ['MediaLoader'],
          }
        : {}),
      ...(this.type === 'video'
        ? {
            nativeControlsForTouch: true,
            playbackRates: [0.5, 1, 1.5, 2, 2.5, 3],
          }
        : {}),
      ...(this.type === 'audio'
        ? {
            nativeControlsForTouch: true,
            controlBar: { fullscreenToggle: false },
            playbackRates: [0.5, 1, 1.5, 2, 2.5, 3],
          }
        : {}),
    };
  }

  constructor(private element: ElementRef) {}

  async ngAfterViewInit() {
    await this.loadPlayer();
    this.enableYoutubeSupport();
  }

  ngOnChanges() {
    if (this.player) {
      this.restartPlayer();
    }
  }

  ngOnDestroy() {
    this.unloadPlayer();
  }

  private enableYoutubeSupport() {
    // initializing videojsYoutube() will set youtube cookies.
    // we want to avoid that until we actually have some youtube content to show
    if (this.type === 'youtube' && !youtubeLoaded) {
      videojsYoutube(videojs.default);
      // make sure its run only once per session
      youtubeLoaded = true;
    }
  }

  private restartPlayer() {
    this.unloadPlayer();
    this.loadPlayer();
  }

  private async loadPlayer() {
    videojs = await import('video.js/dist/alt/video.core.novtt.min.js');

    const domPlayer = document.createElement(this.type === 'audio' ? 'audio' : 'video');
    domPlayer.setAttribute('class', this.className);
    domPlayer.setAttribute('width', '600');
    if (this.type !== 'audio') {
      domPlayer.setAttribute('height', '380');
    }
    domPlayer.setAttribute('controls', 'controls');
    domPlayer.setAttribute('preload', 'auto');
    if (this.autoplay) {
      domPlayer.setAttribute('autoplay', '');
      domPlayer.setAttribute('playsInline', ''); // ios version
      domPlayer.setAttribute('playsinline', '');
    }
    if (this.poster) {
      domPlayer.setAttribute('poster', fixProtocol(this.poster));
    }

    if (this.parentHeight) {
      domPlayer.style.height = '100%';
    }

    (<HTMLElement>this.element.nativeElement).appendChild(domPlayer);
    this.player = videojs.default(domPlayer, this.playerJsConfig, () => {
      // hook up player library events to angular Outputs
      this.player.on('timeupdate', () => this.timeupdate.emit());
      this.player.on('ended', () => this.ended.emit());
      this.player.on('pause', () => this.pause.emit());
      this.player.on('ready', () => this.mediaLoaded.emit());
      this.player.on('error', () => this.mediaLoadError.emit());
    });
  }

  private unloadPlayer() {
    try {
      this.player.off('timeupdate');
      this.player.off('ended');
      this.player.off('pause');
      this.player.dispose();
    } catch (e) {
      // ngOnDestroy is being called after the view is removed which makes the
      // youtube player throw an error https://github.com/angular/angular/issues/14252
    }
  }
}
