/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Creates a copy of the object with the updated deep property.
 *
 * @param obj - The object to update
 * @param path - The path of the property to update
 * @param updater - A function that receives the current value of the deep property and returns the new value
 * @returns A copy of the object with the updated deep property indicated by the path
 *

 *
 * ### Example
 *
 *
 * ```
const state = {
  a: {
    b: {
      c: {
       d: 'Hello'
      }
    }
  }
};

const updated = updateDeep(state, ['a', 'b', 'c', 'd'], (curr) => 'World');

updated.a.b.c.d === 'World'; // ==> true
```
 */
export function updateDeep<T, K1 extends keyof T>(obj: T, path: [K1], updater: (current?: T[K1]) => T[K1]): T;
export function updateDeep<T, K1 extends keyof T, K2 extends keyof T[K1]>(
  obj: T,
  path: [K1, K2],
  updater: (current?: T[K1][K2]) => T[K1][K2],
): T;
export function updateDeep<T, K1 extends keyof T, K2 extends keyof T[K1], K3 extends keyof T[K1][K2]>(
  obj: T,
  path: [K1, K2, K3],
  updater: (current?: T[K1][K2][K3]) => T[K1][K2][K3],
): T;
export function updateDeep<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
>(obj: T, path: [K1, K2, K3, K4], updater: (current?: T[K1][K2][K3][K4]) => T[K1][K2][K3][K4]): T;
export function updateDeep<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4],
>(obj: T, path: [K1, K2, K3, K4, K5], updater: (current?: T[K1][K2][K3][K4][K5]) => T[K1][K2][K3][K4][K5]): T;
export function updateDeep<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4],
  K6 extends keyof T[K1][K2][K3][K4][K5],
>(
  obj: T,
  path: [K1, K2, K3, K4, K5, K6],
  updater: (current?: T[K1][K2][K3][K4][K5][K6]) => T[K1][K2][K3][K4][K5][K6],
): T;
export function updateDeep<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4],
  K6 extends keyof T[K1][K2][K3][K4][K5],
  K7 extends keyof T[K1][K2][K3][K4][K5][K6],
>(
  obj: T,
  path: [K1, K2, K3, K4, K5, K6, K7],
  updater: (current?: T[K1][K2][K3][K4][K5][K6][K7]) => T[K1][K2][K3][K4][K5][K6][K7],
): T;
export function updateDeep<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4],
  K6 extends keyof T[K1][K2][K3][K4][K5],
  K7 extends keyof T[K1][K2][K3][K4][K5][K6],
  K8 extends keyof T[K1][K2][K3][K4][K5][K6][K7],
>(
  obj: T,
  path: [K1, K2, K3, K4, K5, K6, K7, K8],
  updater: (current?: T[K1][K2][K3][K4][K5][K6][K7][K8]) => T[K1][K2][K3][K4][K5][K6][K7][K8],
): T;
export function updateDeep(
  obj: Record<string, any>,
  path: (string | number | symbol)[],
  updater: (current?: any) => any,
) {
  if (path.length === 0) {
    return updater(obj);
  } else {
    const [first, ...rest] = path;
    return {
      ...obj,
      [first]: updateDeep((obj || {})[first.toString()], <any>rest, updater),
    };
  }
}

/**
 * Safely returns a deep propety from the object.
 *
 * @param obj - The object to get the property from
 * @param path - The path of the property to get
 * @returns The property indicated by the path
 *

 *
 * ### Example
 *
 *
 * \`\`\`
 const state = {
  a: {
    b: {
      c: {
       d: 'Hello'
      }
    }
  }
};

 const prop = getDeep(state, ['a', 'b', 'c', 'd']);

 prop === 'Hello'; // ==> true
 \`\`\`
 */
export function getDeep<T, K1 extends keyof T>(obj: T, path: [K1]): T[K1];
export function getDeep<T, K1 extends keyof T, K2 extends keyof T[K1]>(obj: T, path: [K1, K2]): T[K1][K2];
export function getDeep<T, K1 extends keyof T, K2 extends keyof T[K1], K3 extends keyof T[K1][K2]>(
  obj: T,
  path: [K1, K2, K3],
): T[K1][K2][K3];
export function getDeep<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
>(obj: T, path: [K1, K2, K3, K4]): T[K1][K2][K3][K4];
export function getDeep<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4],
>(obj: T, path: [K1, K2, K3, K4, K5]): T[K1][K2][K3][K4][K5];
export function getDeep<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4],
  K6 extends keyof T[K1][K2][K3][K4][K5],
>(obj: T, path: [K1, K2, K3, K4, K5, K6]): T[K1][K2][K3][K4][K5][K6];
export function getDeep(obj: Record<string, any>, path: (string | number | symbol)[]) {
  let curr = obj;
  for (const step of path) {
    if (typeof curr === 'undefined' || curr === null || step === null || typeof step === 'undefined') {
      return curr;
    }

    curr = curr[step.toString()];
  }
  return curr;
}

export function removeObjectNullAndUndefined<T>(obj: T): Partial<T> {
  const newObject: unknown = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined));
  return newObject as Partial<T>;
}

export function removeObjectEmptyStrings<T>(obj: T): Partial<T> {
  const newObject: unknown = Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v !== null && v !== undefined && v.length > 0),
  );
  return newObject as Partial<T>;
}

/**
 *
 * @description Remove duplicates from an array of objects by a key determined by key function
 */
export function uniqBy<T>(arr: T[], key: (i: T) => string | number): T[] {
  const seen: { [prop: string]: unknown } = {};
  return arr.filter(function (item) {
    const k = key(item);
    // eslint-disable-next-line no-prototype-builtins
    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  });
}

/**
 *
 * @description Remove array of keys from an object
 */
export function omit<T>(obj: T, keys: keyof T | Array<keyof T>): Partial<T> {
  keys = Array.isArray(keys) ? keys : [keys];
  // convert keys to strings to account for numbers - that worked with external util before (_.omit)
  const keysAsStrings = keys.map((key) => String(key));
  const result = {} as T;
  for (const key in obj) {
    if (!keysAsStrings.includes(key)) {
      result[key] = obj[key];
    }
  }
  return result;
}
