<ao-message-modal-card [open]="open" [showClose]="false" [modalInsideModal]="modalInsideModal" [overflowVisible]="true">
  <div class="ao-auto-translation-onboarding-modal__header">
    <img src="/assets/online-translator.svg" />
    <span class="ao-font-tiny-bold ao-auto-translation-onboarding-modal__new" translate>New feature</span>
  </div>
  <h3 class="ao-font-title4 ao-auto-translation-onboarding-modal__title" translate>Introducing Translate</h3>
  <p class="ao-font-small ao-auto-translation-onboarding-modal__explanation" translate>
    With Translate you can now machine translate content and comments into your preferred language on demand. Choose
    your preferred language to get started. You can always change this later from your profile settings
  </p>
  <div class="ao-auto-translation-onboarding-modal__select-language" *ngIf="languages">
    <button
      (click)="toggleSearch()"
      class="ao-auto-translation-onboarding-modal__select-box"
      [ngClass]="{ 'ao-auto-translation-onboarding-modal__select-box--error': showError }"
    >
      <span
        class="ao-font-body2 ao-auto-translation-onboarding-modal__select-box--language"
        *ngIf="selectedLanguage; else placeholder"
        >{{ selectedLanguage.languageName }}</span
      >
      <ng-template #placeholder
        ><span class="ao-font-small ao-auto-translation-onboarding-modal__select-box--placeholder" translate
          >Choose preferred language</span
        ></ng-template
      >
      <ao-icon name="keyboard_arrow_down" [size]="18"></ao-icon>
    </button>
    <div *ngIf="searchVisible" class="ao-auto-translation-onboarding-modal__search">
      <ao-social-wall-search-input
        [placeholder]="'Search' | translate"
        [(ngModel)]="searchInput"
        (ngModelChange)="onSearch($event)"
      >
      </ao-social-wall-search-input>
      <ul class="ao-auto-translation-onboarding-modal__list">
        <li class="ao-font-body2 ao-auto-translation-onboarding-modal__list--item" (click)="resetLanguage()" translate>
          None
        </li>
        <li
          class="ao-font-body2 ao-auto-translation-onboarding-modal__list--item"
          *ngFor="let language of filteredLanguages"
          (click)="selectLanguage(language)"
        >
          {{ language.languageName }}
        </li>
      </ul>
    </div>
  </div>
  <button
    class="ao-font-small-bold ao-auto-translation-onboarding-modal__save"
    ao-button
    buttonColor="blue"
    (click)="onConfirm()"
    translate
  >
    Save your preferred language
  </button>
  <button
    class="ao-font-small-bold ao-auto-translation-onboarding-modal__skip"
    ao-button
    buttonColor="ink"
    [outline]="true"
    (click)="onSkip()"
    translate
  >
    Skip
  </button>
</ao-message-modal-card>
