import {
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BaseHeaderComponent, ThemeHandlerService } from '@ao/common-ui';
import { LoginTheme, Theme } from '@ao/data-models';
import { color as c, fixProtocol } from '@ao/utilities';
import { ModuleInsightsComponent } from '../module-insights/module-insights.component';
import { PaginationComponent } from '../pagination/pagination.component';

@Component({
  selector: 'ao-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, OnDestroy {
  @ContentChild('[slot=header]') public shellHeader: BaseHeaderComponent;
  @ContentChild('[slot=insights]')
  public shellInsights: ModuleInsightsComponent;
  @ContentChild(PaginationComponent)
  public shellPagination: PaginationComponent;
  // @ViewChild('socialHeader', { static: true })
  // public socialHeader: CdkPortalOutlet;
  // @ViewChild('socialNotifications', { static: true })
  // public socialNotifications: CdkPortalOutlet;
  @ViewChild('messageSidebar', { static: true })
  public messageSidebar: MatDrawer;
  @ViewChild('socialSidebar', { static: true }) public socialSidebar: MatDrawer;
  @Input() layoutHasHeader!: boolean;
  @Input() isTestAndNavFatureIsOn: boolean;

  get hasHeader() {
    return Boolean(this.shellHeader);
  }

  get hasPagination() {
    return Boolean(this.shellPagination);
  }

  get hasInsights() {
    return Boolean(this.shellInsights);
  }

  @HostBinding('class.ao-shell') className = true;
  @HostBinding('id')
  get id() {
    return this.themeHandler.shellId;
  }

  @Input() smallScreen = false;
  @Input() expandedMenu = false;
  @Input() menuExpandedDesktop = false;

  @Input() set theme(value: Partial<Theme>) {
    this.themeHandler.theme = value;
  }
  @Input() set loginTheme(value: Partial<LoginTheme>) {
    this.themeHandler.loginTheme = value;
  }

  @HostBinding('class.ao-shell--admin-preview')
  @Input()
  adminPreview?: boolean = false;

  @HostBinding('class.ao-shell--admin-preview-url')
  @Input()
  adminPreviewUrl?: boolean = false;

  @Output() toggleMenuSidebar = new EventEmitter();

  constructor(private themeHandler: ThemeHandlerService) {}

  ngOnInit() {
    // prep custom theme options on the message shell level
    // .ao-shell__fixed-header class is for ie11 header fixes
    this.themeHandler.registerStyle('ShellComponent', (theme) => {
      const wrapperElement = document.querySelectorAll('.ao-shell__wrapper')[0];
      return `
      ${
        theme.font_media_id && theme.font
          ? `
      @font-face {
        font-family: messageFont;
        src: ${this.getFontUrls(theme.font.regular)};
        font-display: block;
        font-weight: normal;
        font-style: normal;
      }`
          : ''
      }
      ${
        theme.font_media_id && theme.font_bold_media_id && theme.font
          ? `
        @font-face {
          font-family: messageFont;
          src: ${this.getFontUrls(theme.font.bold)};
          font-display: block;
          font-weight: bold;
      }`
          : ''
      }
      ${
        theme.font_media_id && theme.font_italic_media_id && theme.font
          ? `
        @font-face {
          font-family: messageFont;
          src: ${this.getFontUrls(theme.font.italic)};
          font-display: block;
          font-style: italic;
      }`
          : ''
      }
      ${
        theme.font_media_id && theme.font_bold_italic_media_id && theme.font
          ? `
       @font-face {
          font-family: messageFont;
          src: ${this.getFontUrls(theme.font.boldItalic)};
          font-display: block;
          font-weight: bold;
          font-style: italic;
      }`
          : ''
      }
      .ao-shell__wrapper {
        color: ${c(theme.module_txt_color, '#000000')};
        ${theme.module_txt_line_height ? 'line-height: ' + theme.module_txt_line_height * 0.01 : ''};
        ${
          theme.font
            ? `font-family: messageFont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;`
            : ''
        }

        ${
          // if there is no background image and is not mobile, set the background color to gray
          !this.adminPreview && !theme.screen_bg_images.length && !this.smallScreen ? 'background-color:#dfdfdf;' : ''
        }
      }
      ${this.adminPreview ? '.shell-body-mock' : 'body'} {
        background-color: #dfdfdf;
      }
      ${
        theme.screen_bg_images.length || theme.screen_bg_image_url
          ? `
      ${this.adminPreview ? '.shell-body-mock' : 'body'} {
        background-image: url(${this.themeHandler.getBackgroundImageByAspectRatio(
          theme.screen_bg_images,
          (wrapperElement as HTMLElement)?.offsetWidth,
          (wrapperElement as HTMLElement)?.offsetHeight,
          theme.screen_bg_image_url,
        )});
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
      }
      `
          : ''
      }
      .ao-shell__canvas-wrapper {
        background-color: ${theme.message_bg_images.length ? 'transparent' : c(theme.module_bg_color, 'transparent')};
        ${this.themeHandler.getBackgroundImageByWidth(theme.message_bg_images, theme.message_bg_image_url)}
        ${theme.message_bg_stretched ? `background-size: cover;` : 'background-size: auto;'}
        ${
          // if there is no background image or module_bg_color, set the background of content to white
          !this.adminPreview && !theme.screen_bg_images.length && !theme.module_bg_color ? 'background-color:#fff;' : ''
        }
      }
      .ao-shell__canvas-wrapper {
      background-color: ${theme.message_bg_images.length ? 'transparent' : c(theme.module_bg_color, 'transparent')};
    }
      .viewer-message a {
         color: ${c(theme.module_link_color, 'inherit')};
      }


      ${
        !theme.nav_header_hide
          ? `
          .side-nav {
            margin-top: 52px;
          }`
          : ''
      }
    `;
    });
    this.themeHandler.createStylesheet();
  }

  ngOnDestroy() {
    this.themeHandler.removeStylesheet();
  }

  toggleSidebar(menuOpen: boolean) {
    if (menuOpen && (this.smallScreen || !this.menuExpandedDesktop)) {
      this.toggleMenuSidebar.emit();
    }
  }

  // we often dynamically generate fonts, in those cases, find the base url and add it to the source list in the correct format
  getFontUrls(font) {
    const fonts = ['eot_url', 'otf_url', 'woff_url', 'ttf_url', 'svg_url'];
    // make sure the extension of the original font is included in the list first
    const orderedExtensionList = Array.from(
      new Set([
        ...fonts.filter(
          (key) => font[key] && font.title && font[key].replace(/.*\./, '') === font.title.replace(/.*\./, ''),
        ),
        ...fonts,
      ]),
    );
    return orderedExtensionList
      .filter((key) => font[key])
      .map((key) => {
        const fontUrl = fixProtocol(font[key]);
        switch (key) {
          case 'eot_url':
            return `url(${fontUrl}#iefix) format('embedded-opentype')`;
          case 'otf_url':
            return `url(${fontUrl}) format('opentype')`;
          case 'woff_url':
            return `url(${fontUrl}) format('woff')`;
          case 'ttf_url':
            return `url(${fontUrl}) format('truetype')`;
          case 'svg_url':
            return `url(${fontUrl}#messageFont)`;
        }
      })
      .join(', ');
  }

  get classList() {
    return {
      'sidenav-top-safearea': true,
      'sidenav-top-offset--xlarge': this.isTestAndNavFatureIsOn && this.smallScreen,
      'sidenav-top-offset--large': this.isTestAndNavFatureIsOn && this.smallScreen,
      'sidenav-top-offset--medium': this.isTestAndNavFatureIsOn && !this.smallScreen,
      'sidenav-top-offset--small':
        (this.isTestAndNavFatureIsOn && !this.smallScreen) || (this.layoutHasHeader && !this.smallScreen),
    };
  }
}
