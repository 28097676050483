import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ao-task-list-item',
  templateUrl: './task-list-item.component.html',
  styleUrls: ['./task-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListItemComponent {
  @Input() isComplete = false;
  @Input() urgency?: 'low' | 'medium' | 'high';
  @Input() priority?: 1 | 2 | 3;
  @Input() title?: string;
  @Input() subTitle?: string;
  @Input() loadingSkeleton = false;
  @Input() isRecurrent = false;

  @Output() clickHandler = new EventEmitter<void>();
  @Output() checkboxkHandler = new EventEmitter<void>();

  get stateClasses() {
    return {
      'task-item--isComplete': this.isComplete,
      [`task-item--${this.urgency}`]: this.urgency && !this.isComplete,
      [`task-item--prio-${this.priority}`]: this.priority,
      [`task-item--loading`]: this.loadingSkeleton,
    };
  }

  onClick() {
    this.clickHandler.emit();
  }
  onClickCheckbox(e: Event) {
    e.stopPropagation();
    this.checkboxkHandler.emit();
  }
}
