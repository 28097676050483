<ao-message-modal-card [open]="open" [showClose]="false" [modalInsideModal]="true">
  <div class="ao-font-body2-bold ao-save-content-onboarding-modal__header">
    <ao-tag
      [color]="'blue'"
      class="ao-font-tiny-bold ao-save-content-onboarding-modal__header__tag"
      [iconLeft]="'custom-icons/magic'"
      [passive]="true"
    >
      {{ 'New' | translate }}</ao-tag
    >
    {{ 'Save content you like' | translate }}
  </div>

  <div class="ao-save-content-onboarding-modal__content">
    <p class="ao-font-body2 ao-save-content-onboarding-modal__explanation">
      {{
        'You can now save academies, messages, and social wall posts, so you can easily get back to them later.'
          | translate
      }}
    </p>
    <div class="ao-save-content-onboarding-modal__image">
      <img aoSrc="/assets/save-content-onboarding.gif" />
    </div>
  </div>
  <button
    class="ao-font-body2-bold ao-save-content-onboarding-modal__save"
    ao-button
    buttonColor="blue"
    (click)="onConfirm()"
  >
    {{ 'Got it ' | translate }}
  </button>
</ao-message-modal-card>
