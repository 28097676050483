<ao-base-header layout="center" [showBackButton]="showBackButton" (dismiss)="backClick.emit()">
  <ng-container>
    <ng-template aoHeaderLeft>
      <ao-menu-button
        *ngIf="sidebar && showMenuButton"
        class="ao-header__button"
        (click)="sidebarToggle.emit()"
        [hasUnread]="hasSidebarUnread"
      ></ao-menu-button>
    </ng-template>
    <div class="ao-header__navbar">
      <div *ngIf="isSmallScreen" class="ao-header-spacer"></div>

      <a
        *ngIf="!theme?.nav_logo_hide && theme?.nav_click_target"
        routerLink="javascript:function() { return false; }"
        [ngClass]="[
          isSmallScreen ? 'ao-header__logo-link--center' : 'ao-header__logo-link',
          hiddenIcons || logoClickDisabled ? 'ao-header__logo-link__disabled' : ''
        ]"
      >
        <img
          [aoSrc]="logo | fixProtocol"
          class="ao-header__logo"
          (click)="!hiddenIcons && homePageClick.emit($event)"
          [attr.alt]="'Header logo' | translate"
        />
      </a>
      <img
        *ngIf="!theme?.nav_logo_hide && !theme?.nav_click_target"
        [aoSrc]="logo | fixProtocol"
        class="ao-header__logo"
      />
      <div
        *ngIf="!isSmallScreen"
        [ngClass]="theme?.nav_logo_hide ? 'ao-header__navbar-navigation--no-logo' : 'ao-header__navbar-navigation'"
      >
        <ng-content select="[navbar]"></ng-content>
        <ng-content select="ao-nav-bar-content"></ng-content>
      </div>

      <ng-container *ngIf="chatLink && !hiddenIcons">
        <button
          [ngStyle]="theme?.nav_logo_hide && isSmallScreen ? { 'grid-column-start': 3 } : {}"
          class="ao-header__button ao-header__button-icon"
          name="chat"
          (click)="chatClick.emit(chatLink)"
          style="justify-self: end"
          [disabled]="disabledIcons"
        >
          <ao-badge-icon
            [hasUnread]="chatHasUnreadMessages"
            [imgUrl]="'custom-icons/chat-icon'"
            [ngClass]="page === 'CHAT' ? 'ao-header__chat-icon--highlight' : 'ao-header__chat-icon'"
          >
          </ao-badge-icon>
        </button>
      </ng-container>
    </div>
  </ng-container>
</ao-base-header>
