export interface ApiResponseError {
  code: string;
  data: [];
  message: string;
  msg: string;
  status: string;
  headers: string[];
  level: string;
}

export enum ErrorStateItemType {
  Directory,
}
