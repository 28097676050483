<div class="ao-academy-message">
  <ao-academy-header
    [title]="academy.title"
    [sections]="academy.sections"
    [description]="academy.description"
    [categories]="academy.categories"
    [date]="academy.date"
    [progress]="academy.progress"
    [customProgressLabel]="progressInsightsSettings?.title"
    [image]="academy.coverMedia || academy.coverImageUrl || academy.image"
    [academyIsSaved]="academy.saved"
    [saveContentEnabled]="saveContentEnabled"
    [readOnly]="isTest"
    (saveAcademy)="saveAcademy.emit()"
    (unsaveAcademy)="unsaveAcademy.emit()"
  >
  </ao-academy-header>
  <div class="ao-academy-message__sections">
    <ao-academy-section
      class="ao-academy-message__section"
      *ngFor="let section of academy.sections; trackBy: trackById"
      [academyId]="academy.id"
      [sectionTitle]="section.name"
      [trainings]="section.trainings"
      [disabled]="section.disabled"
      [progress]="section.progress"
      [collapsed]="sectionStatus[section.id]"
      (toggleCollapsed)="onToggleCollapsed(section.id)"
    >
    </ao-academy-section>
  </div>
</div>
