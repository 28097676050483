import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

import { TaskDetailsEntity, TaskEntity } from '../viewer-tasks.state';

export const fetchTasks = createAction('[task/API] Fetch tasks', props<{ sortBy?: string; orderDir?: string }>());
export const fetchTasksSuccess = createAction('[task/API] Fetch tasks success', props<{ tasks: TaskEntity[] }>());
export const fetchTasksError = createAction('[task/API] Fetch tasks error', props<{ error: unknown }>());

export const fetchTaskById = createAction('[task/API] Fetch task by ID', props<{ taskId: number }>());
export const fetchTaskByIdSuccess = createAction(
  '[task/API] Fetch tasks by ID success',
  props<{ task: TaskDetailsEntity }>(),
);
export const fetchTaskByIdError = createAction(
  '[task/API] Fetch tasks by ID error',
  props<{ taskId: number; error: unknown }>(),
);

export const completeTaskById = createAction('[task/API] Complete task by ID', props<{ taskId: number }>());
export const completeTaskByIdSuccess = createAction(
  '[task/API] Complete tasks by ID success',
  props<{ taskId: number }>(),
);
export const completeTaskByIdError = createAction(
  '[task/API] Complete tasks by ID error',
  props<{ taskId: number; error: unknown }>(),
);
export const uncompleteTaskById = createAction('[task/API] Uncomplete task by ID', props<{ taskId: number }>());
export const uncompleteTaskByIdSuccess = createAction(
  '[task/API] Uncomplete tasks by ID success',
  props<{ taskId: number }>(),
);
export const uncompleteTaskByIdError = createAction(
  '[task/API] Uncomplete tasks by ID error',
  props<{ taskId: number; error: unknown }>(),
);

// genric updating status
export const setTaskUpdating = createAction(
  '[task/API] Set tasks update status',
  props<{ taskId: number; isUpdating: boolean }>(),
);

export const getNewTaskCount = createAction('[task/API] getNewTaskCount');
export const getNewTaskCountSuccess = createAction('[task/API] getNewTaskCount Succcess', props<{ count: number }>());
export const getNewTaskCountFail = createAction('[task/API] getNewTaskCount Fail', props<{ error?: unknown }>());

// generated
export const loadTasks = createAction('[task/API] Load tasks', props<{ tasks: TaskEntity[] }>());

export const addTask = createAction('[task/API] Add task', props<{ task: TaskEntity }>());

export const upsertTask = createAction('[task/API] Upsert task', props<{ task: TaskEntity }>());

export const addTasks = createAction('[task/API] Add tasks', props<{ tasks: TaskEntity[] }>());

export const upsertTasks = createAction('[task/API] Upsert tasks', props<{ tasks: TaskEntity[] }>());

export const updateTask = createAction('[task/API] Update task', props<{ task: Update<TaskEntity> }>());

export const updateTasks = createAction('[task/API] Update tasks', props<{ tasks: Update<TaskEntity>[] }>());

export const deleteTask = createAction('[task/API] Delete task', props<{ id: string }>());

export const deleteTasks = createAction('[task/API] Delete tasks', props<{ ids: string[] }>());

export const clearTasks = createAction('[task/API] Clear tasks');
