import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { dateIsPassed, dateIsToday, onceWithLatest, WINDOW } from '@ao/utilities';
import { ViewerCoreFacade } from '@ao/viewer-core';
import { TaskEntity, ViewerTaskFacade } from '@ao/viewer-task-management-store';
import { ViewerTrackingService } from '@ao/viewer-tracking-store';
import { of, switchMap } from 'rxjs';

@Component({
  selector: 'ao-task-list-page',
  templateUrl: './task-list-page.component.html',
  styleUrls: ['./task-list-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskListPageComponent implements OnInit {
  router = inject(Router);
  routes = inject(ActivatedRoute);
  viewerCore = inject(ViewerCoreFacade);
  viewerTaskFacade = inject(ViewerTaskFacade);
  viewerTrackingService = inject(ViewerTrackingService);
  window = inject(WINDOW);

  activeTab: 'active' | 'completed' = this.routes.snapshot.data['activeTab'] === 'completed' ? 'completed' : 'active';

  tasks$ = this.viewerTaskFacade.tasks$;
  tasksIsLoading$ = this.viewerTaskFacade.tasksIsLoading$;

  openSections: string[] = [];

  completedTasks$ = this.tasks$.pipe(switchMap((tasks) => of(tasks.filter((task) => task.status === 'completed'))));
  uncompletedTasks$ = this.tasks$.pipe(switchMap((tasks) => of(tasks.filter((task) => task.status !== 'completed'))));
  uncompletedTaskSections$ = this.viewerTaskFacade.uncompletedTaskSections$;
  get isIsolatedRoute() {
    return this.router.url === '/task';
  }

  _showConfirmReopen = false;
  _confirmationTaskId?: number = undefined;

  ngOnInit(): void {
    this.viewerTaskFacade.fetchTasks();
  }

  uncompleteTaskPrompt(task: TaskEntity) {
    this._confirmationTaskId = task.id;
    this._showConfirmReopen = true;
  }
  uncompleteTaskConfirmation() {
    if (this._confirmationTaskId) {
      this.viewerTaskFacade.uncompleteTask(this._confirmationTaskId);
      this._confirmationTaskId = undefined;
    }
    this._showConfirmReopen = false;
  }
  dismissUncomplete() {
    this._showConfirmReopen = false;
  }

  toggleSectionOpen(id: string) {
    if (this.openSections.includes(id)) {
      this.openSections = this.openSections.filter((sectionId) => sectionId !== id);
    } else {
      this.openSections.push(id);
    }
  }
  isSectionOpen(id: string) {
    return !this.openSections.includes(id);
  }

  goHome() {
    this.window.location.href = '/'; // do this now to rely on the server to redirect to correct homepage keycode for the user
  }
  goToTask(task: TaskEntity) {
    if (this.isIsolatedRoute) {
      this.router.navigate(['/task', task.id]);
    } else {
      onceWithLatest(this.viewerCore.origin$, this.viewerCore.keycode$, (origin, keycode) => {
        this.router.navigate(['/', origin, keycode, 'task', task.id]);
      });
    }
  }

  setActiveTab(tab: 'active' | 'completed') {
    this.activeTab = tab;
    if (tab === 'completed') {
      this.viewerTaskFacade.fetchTasks('lastStatusUpdatedAt', 'desc');
    } else {
      this.viewerTaskFacade.fetchTasks();
    }
  }

  getPrioAsNumber(task: TaskEntity) {
    switch (task.priority) {
      case 'PRIORITY_1':
        return 1;
      case 'PRIORITY_2':
        return 2;
      case 'PRIORITY_3':
        return 3;
    }
    return undefined;
  }
  getUrgency(task: TaskEntity) {
    if (task.dueDate && dateIsPassed(task.dueDate)) {
      return 'high';
    }
    if (task.dueDate && dateIsToday(task.dueDate)) {
      return 'medium';
    }
    return 'low';
  }

  toggleTaskCompletion(task: TaskEntity) {
    this.viewerTrackingService.taskCompleted(task.id, 'overview');
    this.viewerTaskFacade.completeTask(task.id);
  }
  isTaskUpdating$(taskId: number) {
    return this.viewerTaskFacade.selectTaskUpdatingById$(taskId);
  }
}
