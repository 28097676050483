import * as AllErrorConstants from './error.constant';
import * as TermsOfUseConstant from './terms_of_use.constant';
import * as TimeConstant from './time.constant';
import * as TrackingConstants from './tracking.constants';

export const ERROR_CONSTANT = AllErrorConstants;
export const TERMS_OF_USE_CONSTANT = TermsOfUseConstant;
export const TIME_CONSTANT = TimeConstant;
export const TRACKING_CONSTANTS = TrackingConstants;
export const MULTIPART_FILE_CHUNK_SIZE = 10000000;

export * from './color.constants';
export * from './email-validation.constants';
export * from './error.constant';
export * from './helpdesk-urls.constants';
export * from './locales.constants';
export * from './profile.constants';
export * from './social.constants';
export * from './table.constant';
export * from './terms_of_use.constant';
export * from './time.constant';
export * from './tracking.constants';
