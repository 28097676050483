import { Action, ActionReducer } from '@ngrx/store';

export const localStorageSync = <S, A extends Action = Action>(stateKey: string) => {
  const localStorageKey = `__ao-${stateKey}__`;

  const setSavedState = (state: S[keyof S]) => {
    if (window.localStorage) {
      window.localStorage.setItem(localStorageKey, JSON.stringify({ [stateKey]: state }));
    }
  };

  const getSavedState = (): S[keyof S] | undefined => {
    if (window.localStorage) {
      const savedState = window.localStorage.getItem(localStorageKey);
      return savedState ? JSON.parse(savedState)[stateKey] : undefined;
    }
  };

  let onInit = true;

  return (reducer: ActionReducer<S, A>): ActionReducer<S, A> => {
    return (state: S | undefined, action: A): S => {
      const nextState = reducer(state, action);

      if (onInit) {
        onInit = false;
        const savedState = getSavedState();
        if (savedState !== undefined) {
          return { ...nextState, [stateKey]: savedState };
        }
      } else {
        setSavedState(nextState[stateKey]);
      }

      return nextState;
    };
  };
};
