import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { merge, Observable, Subject } from 'rxjs';
import { filter, mapTo, takeUntil } from 'rxjs/operators';
import { Ng1Events } from '../ng1-events.service';
import { ModalRef } from './modal-ref';
import { Modal } from './modal.interface';

export interface ModalConfig {
  width?: number | string;
  hasBackdrop?: boolean;
  backdropClickCloses?: boolean;
  escapeCloses?: boolean;
  navigationCloses?: boolean;
  backdropClass?: string[];
}

@Injectable()
export class ModalService implements OnDestroy {
  private destroy$ = new Subject<void>();
  constructor(private overlay: Overlay, private router: Router, private ng1Events: Ng1Events) {}

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openWithPortal<T extends Modal>(
    portal: ComponentPortal<T> | TemplatePortal<{ instance: T }>,
    config: ModalConfig = {},
  ) {
    config = {
      backdropClickCloses: true,
      escapeCloses: true,
      hasBackdrop: true,
      navigationCloses: true,
      ...config,
    };
    const overlayRef = this.overlay.create({
      hasBackdrop: config.hasBackdrop,
      ...(config.width ? { width: config.width } : {}),
      maxWidth: '95vw',
      maxHeight: '95vh',
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      ...(config.backdropClass ? { backdropClass: config.backdropClass } : {}),
    });
    let modalRef: ModalRef<T, T extends Modal<infer U> ? U : unknown>;
    if (portal instanceof ComponentPortal) {
      const ref = overlayRef.attach(portal);
      modalRef = new ModalRef(overlayRef, ref.instance, this.destroy$.asObservable(), true);
    } else {
      overlayRef.attach(portal);
      modalRef = new ModalRef<T>(overlayRef, portal.context.instance, this.destroy$.asObservable(), false);
    }
    const dismisses: Observable<string>[] = [];
    if (config.hasBackdrop && config.backdropClickCloses) {
      dismisses.push(overlayRef.backdropClick().pipe(mapTo('backdropClick')));
    }
    if (config.escapeCloses) {
      dismisses.push(
        overlayRef.keydownEvents().pipe(
          filter((e) => e.keyCode === ESCAPE && !hasModifierKey(e)),
          mapTo('escape'),
        ),
      );
    }
    if (config.navigationCloses) {
      dismisses.push(
        this.router.events.pipe(
          filter((e) => e instanceof NavigationStart),
          takeUntil(modalRef.result),
          mapTo('navigation'),
        ),
      );
      dismisses.push(
        this.ng1Events.events$.pipe(
          filter(({ type }) => type === '$locationChangeStart'),
          takeUntil(modalRef.result),
          mapTo('navigation'),
        ),
      );
    }
    merge(...dismisses).subscribe((val) => modalRef.instance.dismiss.next(val));
    return modalRef;
  }

  openWithComponentType<T extends Modal>(type: new (...args: any[]) => T, config: ModalConfig = {}) {
    const portal = new ComponentPortal(type);
    return this.openWithPortal(portal, config);
  }

  openWithConfig<T extends C & Modal, C>(type: new (...args: any[]) => T, props: C, config: ModalConfig = {}) {
    const ref = this.openWithComponentType(type, config);
    for (const [key, value] of Object.entries(props)) {
      ref.instance[key] = value;
    }
    return ref;
  }
}
