<ng-container [ngSwitch]="type">
  <ao-default-error
    class="ao-empty-state-item__directory"
    errorImageHeight="auto"
    errorImageSrc="/assets/img/illustrations/vacation-time.svg"
    *ngSwitchCase="EmptyStateItemType.ContactDirectory"
  >
    <p class="ao-font-title4 ao-empty-state-item__directory--title" translate>Looks like no one is here</p>
    <p class="ao-font-body2 ao-empty-state-item__directory--subtitle" translate>Perhaps they’re on vacation?</p>
  </ao-default-error>
  <ao-default-error
    class="ao-empty-state-item__directory"
    errorImageHeight="auto"
    errorImageSrc="/assets/img/illustrations/people-search.svg"
    *ngSwitchCase="EmptyStateItemType.SubordinateDirectory"
  >
    <p class="ao-font-title4 ao-empty-state-item__directory--title" translate>We couldn't find any matches</p>
    <p class="ao-font-body2 ao-empty-state-item__directory--subtitle" translate>Try a different search</p>
  </ao-default-error>
</ng-container>
