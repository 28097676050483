import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { ModalContentDirective, ModalFooterDirective, ModalHeaderDirective } from './modal.directive';

@Component({
  selector: 'ao-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('container', [
      state(
        'closed',
        style({
          opacity: 0,
          transform: 'scale(0.7)',
        }),
      ),
      state(
        'open',
        style({
          transform: 'none',
          opacity: 1,
        }),
      ),
      transition('closed => open', [animate('150ms cubic-bezier(0, 0, 0.2, 1)')]),
      transition('open => closed', [animate('0ms cubic-bezier(0, 0, 0.2, 1)')]),
    ]),
  ],
})
export class ModalComponent implements OnInit {
  @HostBinding('class.ao-modal') className = true;
  @HostBinding('class.ao-modal--full-height')
  @Input()
  fullHeight?: boolean;
  @Input() headerText?: string = '';
  @Input() subHeaderText?: HTMLElement | string = '';
  @Input() centerAligned?: boolean;
  @Input() footerJustify?: 'right' | 'space-between' = 'right';
  @Input() headerSeparator?: boolean;

  @Input() showClose = true;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() close = new EventEmitter();

  @ContentChild(ModalHeaderDirective, { read: TemplateRef }) header: TemplateRef<any>;
  @ContentChild(ModalContentDirective, { read: TemplateRef }) content: TemplateRef<any>;
  @ContentChild(ModalFooterDirective, { read: TemplateRef }) footer: TemplateRef<any>;

  opened = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.opened = false;
    setTimeout(() => {
      this.opened = true;
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    }, 0);
  }
  onClose() {
    this.close.next(null);
  }
}
