import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { VIEWER_LANGUAGES } from '@ao/data-models';
import { environment } from '@ao/environments';
import {
  AoIdHeaderInterceptorProvider,
  CustomRouterStateSerializer,
  EMITTER_CONFIG,
  OneTrustConsentService,
  RouterEffects,
  capitalize,
  lowerCase,
  routerReducer,
  routerStoreProvider,
} from '@ao/utilities';
import { AppStoreModule } from '@ao/viewer-app-store';
import { RootComponent } from '@ao/viewer-core';
import { ViewerUiTranslateLoaderService } from '@ao/viewer-ui';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { AppRoutingModule } from './app-routing.module';
@NgModule({
  bootstrap: [RootComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    StoreModule.forRoot(
      { router: routerReducer },
      {
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
          strictActionTypeUniqueness: true,
        },
      },
    ),
    EffectsModule.forRoot([RouterEffects]),
    StoreRouterConnectingModule.forRoot(),
    ...(environment.production ? [] : [StoreDevtoolsModule.instrument()]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: ViewerUiTranslateLoaderService,
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      scope: '/',
      enabled: false, // environment.production
    }),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AppStoreModule,
  ],
  providers: [
    AoIdHeaderInterceptorProvider,
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    routerStoreProvider,
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: {
        locales: Object.values(VIEWER_LANGUAGES).map((l) => l.locale),
        formatters: {
          capitalize,
          lowerCase,
        },
      },
    },
    { provide: EMITTER_CONFIG, useValue: environment.emitterConfig },
    {
      provide: APP_INITIALIZER,
      useFactory: (oneTrustService: OneTrustConsentService) => () => {
        return oneTrustService.init();
      },
      deps: [OneTrustConsentService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
